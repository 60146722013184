import { render, staticRenderFns } from "./unit.vue?vue&type=template&id=950480a2&scoped=true&"
import script from "./unit.vue?vue&type=script&lang=js&"
export * from "./unit.vue?vue&type=script&lang=js&"
import style0 from "./unit.vue?vue&type=style&index=0&id=950480a2&prod&scoped=true&lang=less&"
import style1 from "./unit.vue?vue&type=style&index=1&id=950480a2&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../yiweb/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "950480a2",
  null
  
)

export default component.exports