<template>
  <Tooltip v-if="row.pfmMonth" :disabled="!row.pfmMonthIsDel" transfer content="该月度绩效已被删除">
    <div style="display: flex;flex-direction: column;align-items: center;">
      <Tag class="noborder" :color="row.pfmMonthIsDel ? 'red' : 'default'">{{row.pfmMonth.substr(0, 7)}}</Tag>
      <Tag class="noborder" v-if="row.pfmMonthStatus === 'end'" color="geekblue">已发布</Tag>
      <Tag class="noborder" v-else-if="row.pfmMonthStatus === 'divide'" color="purple">分配中</Tag>
      <Tag class="noborder" v-else-if="row.pfmMonthStatus === 'compute'" color="volcano">计算中</Tag>
      <Tag class="noborder" v-else-if="row.pfmMonthStatus === 'plan'" color="orange">准备中</Tag>
    </div>
  </Tooltip>
  <Tag class="noborder" v-else color="geekblue">未分配</Tag>
  <!-- <Poptip @on-popper-hide="msg.error = null; msg.success = null" v-else trigger="click" transfer title="请选择要分配的月度绩效">
    <Tag color="geekblue">未分配</Tag>
    <div slot="content">
      <fm-form>
        <fm-form-item>
          <Select transfer placeholder="请选择月度绩效" filterable clearable v-model="pfmMonthId">
            <Option v-for="item in monthList" :key="item.id" :value="item.id">{{item.month}} - {{item.statusText}}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item>
          <Button type="primary" :loading="loading" @click="submit">确定分配</Button>
        </fm-form-item>
        <fm-form-item v-if="msg.error || msg.success">
          <Alert v-if="msg.error" type="warning" show-icon>{{msg.error}}</Alert>
          <Alert v-if="msg.success" type="warning" show-icon>{{msg.success}}</Alert>
        </fm-form-item>
      </fm-form>
    </div>
  </Poptip> -->
</template>

<script>
import { request } from '@/api'

export default {
  props: {
    row: {
      type: Object, default: null
    },
    monthList: {
      type: Array, default: () => []
    }
  },
  data () {
    return {
      pfmMonthId: null,
      loading: false,
      msg: { error: null, success: null }
    }
  },
  methods: {
    async submit () {
      this.msg.success = null
      if (!this.pfmMonthId) {
        this.msg.error = '请选择要分配的月度绩效'
        return
      }
      this.msg.error = null

      try {
        this.loading = true
        await request('/performance/pfm_project_bill/to_month', 'post', { pfmMonthId: this.pfmMonthId, ids: this.row.id })
        this.loading = false
        this.msg.success = '已分配'
      } catch (e) {
        this.msg.error = '分配失败'
        this.loading = false
      }
    }
  },
}
</script>