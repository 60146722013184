<style scoped lang="less">
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EEE;
    padding-bottom: 10px;
    .unit-name {
      font-weight: 800;
    }
  }
  .fields {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .consume-list {
    margin-top: 10px;
  }
  .pfm-month-consume {
    &.flex {
      display: flex;
      flex-direction: column;
      .consume-list {
        height: 0;
        flex: 1;
      }
    }
  }
</style>

<style>
  .pfm-month-consume .ivu-tag {
    border: none !important;
  }
</style>

<template>
  <div class="pfm-month-consume" :class="{ flex }">
    <Row align="middle" type="flex" justify="space-between">
      <i-col>
        <fm-title style="margin: 0 10px;" :title-text="title" :note-text="'共' + page.total + '项'"></fm-title>
      </i-col>
      <i-col>
        <Row style="margin-right: 5px;" type="flex" :gutter="10" :wrap="false">
          <i-col v-if="back"><Button icon="ios-arrow-back" @click="backClick">返回</Button></i-col>
          <i-col v-if="pfmMonthStatus === 'plan' && mode === 'manage' && false">
            <Button v-if="pfmMonthId" icon="md-refresh-circle" @click="initMonth">初始化</Button>
            <Poptip v-else trigger="click" transfer title="请选择要初始化的月份">
              <Button :loading="loading.init" icon="md-refresh-circle">初始化</Button>
              <div slot="content">
                <Select placeholder="请选择月度绩效" filterable clearable v-model="initPfmMonthId">
                  <Option v-for="item in monthList" :key="item.id" :value="item.id">{{item.month}} - {{item.statusText}}</Option>
                </Select>
                <Button type="primary" :loading="loading.init" @click="initMonth" style="display: block;width: 100%;margin-top: 10px;">确定</Button>
              </div>
            </Poptip>
          </i-col>
          <i-col><Button icon="md-refresh-circle" :loading="loading.load" @click="loadData">刷新</Button></i-col>
          <i-col v-if="pfmMonthStatus === 'plan' && mode === 'manage'">
            <Poptip style="margin-right: 10px;" confirm @on-ok="dataTruncate" title="确定清空吗？">
              <Button :loading="loading.sync" type="error" icon="md-trash">清空</Button>
            </Poptip>
            <Poptip style="margin-right: 10px;" confirm @on-ok="dataSync" title="确定同步吗？">
              <Button :loading="loading.sync" type="info" icon="md-sync">同步</Button>
            </Poptip>
            <input type="file" ref="import" accept=".xlsx,.xls" style="display: none;" @input="importData" />
            <Button @click="$refs.import.click()" :loading="loading.sync" type="primary" icon="md-cloud-upload">导入</Button>
          </i-col>
          <i-col v-if="pfmMonthStatus === 'plan' && mode === 'manage'"><Button icon="md-add-circle" @click="addClick" type="primary">新增</Button></i-col>
        </Row>
      </i-col>
    </Row>
    <Row type="flex" justify="space-between" :wrap="false" :gutter="10" style="margin: 0 10px;">
      <i-col>
        <Row type="flex" :wrap="false" :gutter="10">
          <i-col>
            <i-input placeholder="请输入搜索内容" search @on-blur="onSearch" @on-search="onSearch" @on-clear="onSearch" clearable v-model="query.value">
              <Select slot="prepend" style="width: 100px" v-model="query.key">
                <Option value="patientName">患者名称</Option>
                <Option value="hisCode">his编码</Option>
              </Select>
            </i-input>
          </i-col>
          <i-col v-if="!pfmMonthId">
            <Select @on-change="onSearch" @on-clear="onSearch" placeholder="月度绩效" filterable clearable v-model="query.pfmMonthId">
              <Option v-for="item in monthList" :key="item.id" :value="item.id">{{item.month}} - {{item.statusText}}</Option>
            </Select>
          </i-col>
          <i-col>
            <Select @on-change="onSearch" @on-clear="onSearch" placeholder="入住科室" filterable clearable v-model="query.pfmOrgId">
              <Option v-for="item in orgList" :key="item.id" :value="item.id">{{item.name}}</Option>
            </Select>
          </i-col>
          <i-col>
            <Select @on-change="onSearch" @on-clear="onSearch" placeholder="管床医生" filterable clearable v-model="query.pfmWorkerId">
              <Option v-for="item in workerList" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </i-col>
          <i-col>
            <Select @on-change="onSearch" @on-clear="onSearch" placeholder="入住考核单元" filterable clearable v-model="query.pfmUnitId">
              <Option v-for="item in unitList" :key="item.id" :value="item.id">{{item.name}}</Option>
            </Select>
          </i-col>
        </Row>
      </i-col>
    </Row>
    <div class="consume-list" ref="content">
      <Table ref="table" :loading="loading.load" @on-sort-change="onSortChange" :data="dataList" :columns="columns" :height="tableHeight" @on-selection-change="onSelectionChange">
        <template slot-scope="{ row }" slot="month">
          <FieldMonth :monthList="monthList" :row="row" />
        </template>
        <template slot-scope="{ row }" slot="action">
          <Button icon="ios-brush" type="text" size="small" style="margin-right: 5px" @click="itemEdit(row)">修改</Button>
          <Poptip transfer confirm @on-ok="itemDel(row)" title="确定删除吗？">
            <Button icon="md-trash" size="small" type="text">删除</Button>
          </Poptip>
        </template>
      </Table>
    </div>
    <div style="padding: 10px;">
      <Page @on-change="onPageChange" :total="page.total" :current="page.num" :page-size="page.size" show-total show-elevator />
    </div>
    <Drawer draggable :mask-closable="false" :mask="!inner" :inner="inner" :transfer="!inner" :title="formData.id ? '修改' : '新增'" width="40" placement="right" closable v-model="status.drawer">
      <fm-form v-if="status.drawer" label-alone label-align="left">
        <fm-form-item label="his编码">
          <fm-input-new v-model="formData.hisCode" placeholder="请输入his编码" clearable />
        </fm-form-item>
        <fm-form-item label="患者名称">
          <fm-input-new v-model="formData.patientName" placeholder="请输入患者名称" clearable />
        </fm-form-item>
        <fm-form-item label="科室">
          <Select placeholder="请选择科室" filterable clearable v-model="formData.pfmOrgId">
            <Option v-for="item in orgList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.hisCode }}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item label="考核单元">
          <Select placeholder="请选择考核单元" filterable clearable v-model="formData.pfmUnitId">
            <Option v-for="item in unitList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.code }}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item v-if="!pfmMonthId && !formData.id" label="月度绩效">
          <Select placeholder="请选择月度绩效" filterable clearable v-model="formData.pfmMonthId">
            <Option v-for="item in monthList" :key="item.id" :value="item.id">{{item.month}} - {{item.statusText}}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item label="管床医生">
          <Select placeholder="请选择管床医生" filterable clearable v-model="formData.pfmWorkerId">
            <Option v-for="item in workerList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.hisCode }}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item label="入科时间">
          <DatePicker v-model="formData.inTime" type="datetime" placeholder="请选择入科时间"></DatePicker>
        </fm-form-item>
        <fm-form-item label="出科时间">
          <DatePicker v-model="formData.outTime" type="datetime" placeholder="请选择出科时间"></DatePicker>
        </fm-form-item>
        <fm-form-item>
          <div style="display: flex;">
            <Button :loading="loading.submit" style="flex: 1;" @click="status.drawer = false" type="default">取消</Button>
            <Button :loading="loading.submit" style="margin-left: 10px;flex: 1;" @click="submit" type="info">确定{{(formData.id ? '修改' : '新增')}}</Button>
          </div>
        </fm-form-item>
      </fm-form>
    </Drawer>
    <Drawer :mask="false" draggable :mask-closable="false" @on-close="$refs.table.selectAll(false)" :inner="inner" :transfer="!inner" :title="'住院明细批量操作 - ' + selectionList.length + '条'" width="40" placement="right" closable v-model="batch.show">
      <Divider orientation="left">批量分配</Divider>
      <Alert show-icon closable>已分配的住院明细，不可重复分配，仅可分配到准备中的月度绩效</Alert>
      <fm-form>
        <fm-form-item>
          <Select placeholder="请选择月度绩效" filterable clearable v-model="batch.assginPfmMonthId">
            <Option v-for="item in monthList.filter(v => v.status === 'plan')" :key="item.id" :value="item.id">{{item.month}} - {{item.statusText}}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item>
          <Poptip transfer confirm @on-ok="batchAssignMonth" title="确定分配吗？">
            <Button type="primary" :loading="loading.batch">确定批量分配</Button>
          </Poptip>
        </fm-form-item>
        <fm-form-item v-if="msg.batchAssign">
          <Alert v-if="msg.batchAssign" type="warning" show-icon>{{msg.batchAssign}}</Alert>
        </fm-form-item>
      </fm-form>
      <Divider orientation="left">批量移除月度绩效</Divider>
      <Poptip transfer confirm @on-ok="batchRemoveMonth" title="确定移除吗？">
        <Button :loading="loading.batch" long type="error">移除</Button>
      </Poptip>
      <Divider orientation="left">批量删除</Divider>
      <Poptip transfer confirm @on-ok="batchDel" title="确定删除吗？">
        <Button :loading="loading.batch" long type="error">批量删除</Button>
      </Poptip>
    </Drawer>
  </div>
</template>

<script>
import { request } from '@/api'

import FieldMonth from './fields/month.vue'
import { xlsxDataMapping } from '../lib'

function getDefault () {
  return {
    id: null,
    hisCode: null,
    patientName: null,
    pfmOrgId: null,
    pfmUnitId: null,
    pfmWorkerId: null,
    inTime: null,
    outTime: null,
    pfmMonthId: null
  }
}

function getDefaultQuery () {
  return {
    key: 'patientName',
    value: null,
    type: null,
    pfmMonthId: null,
    pfmOrgId: null,
    pfmWorkerId: null,
    pfmUnitId: null,
    sort: { key: 'inTime', type: 'desc' }
  }
}

export default {
  components: { FieldMonth },
  props: {
    mode: { type: String, default: 'view' },
    back: { type: Boolean, default: false },
    flex: { type: Boolean, default: false },
    title: { type: String, default: '办公用品耗材' },
    inner: { type: Boolean, default: false },
    pfmMonthId: { type: Number, default: null },
    pfmMonthStatus: { type: String, default: 'plan' }
  },
  data () {
    return {
      dataList: [],
      unitList: [],
      workerList: [],
      selectionList: [],
      formData: getDefault(),
      query: getDefaultQuery(),
      status: {
        drawer: false,
        details: false
      },
      loading: {
        load: false,
        submit: false,
        init: false,
        batch: false,
        sync: false
      },
      msg: {
        batchAssign: null
      },
      page: {
        num: 1,
        size: 10,
        total: 0
      },
      tableHeight: null,
      initPfmMonthId: null,
      batch: {
        show: false,
        assginPfmMonthId: null
      },
    }
  },
  computed: {
    orgList () {
      return this.$store.getters.pfmOrgList
    },
    monthList () {
      return this.$store.getters.pfmMonthList
    },
    columns () {
      const columns = [
        { key: 'hisCode', title: 'his编码', fixed: 'left', width: 180, ellipsis: true, tooltip: true },
        { key: 'patientName', title: '患者名称', fixed: 'left', width: 120, ellipsis: true, tooltip: true },
        { key: 'pfmMonth', title: '绩效月份', fixed: 'left', align: 'center', width: 110, slot: 'month' },
        { key: 'pfmOrgName', title: '入住科室', width: 200 },
        { key: 'pfmWorkerName', title: '管床医生', width: 120 },
        { key: 'pfmUnitName', title: '入住考核单元', width: 200 },
        { key: 'inTime', title: '入科时间', width: 170, sortable: 'custom', ellipsis: true, tooltip: true },
        { key: 'outTime', title: '出院时间', width: 170, sortable: 'custom', ellipsis: true, tooltip: true }
      ]

      if (this.pfmMonthStatus === 'plan' && this.mode === 'manage') {
        return [
          { type: 'selection', width: 60, align: 'center', fixed: 'left' },
          ...columns,
          { key: 'action', title: '操作', slot: 'action', width: 180, fixed: 'right' }
        ]
      } else {
        return columns
      }
    }
  },
  watch: {
    mode () {
      this.page.num = 1
      this.loadData()
      this.loadUnitList()
      this.loadWorkerList()
    }
  },
  methods: {
    async importData ({ target }) {
      if (!target.files.length) {
        return
      }
      const keyMap = [
        { title: '姓名', key: 'patientName' },
        { title: '住院科室', key: 'pfmOrgName' },
        { title: '经管医生', key: 'pfmWorkerName' },
        {
          title: '入院日期', key: 'inTime', convert: (val) => {
            return this.$datetime.format(new Date(val), 'Y-M-D H:I:S')
          }
        },
        {
          title: '出科日期', key: 'outTime', convert: (val) => {
            return this.$datetime.format(new Date(val), 'Y-M-D H:I:S')
          }
        },
        {
          title: 'basisTime', key: 'basisTime', convert: (val) => {
            return this.$datetime.format(new Date(val), 'Y-M-D H:I:S')
          }, mapping: false
        }
      ]
      try {
        this.$Spin.show({
          render: (h) => {
            return h('div', [
              h('Icon', {
                'class': 'demo-spin-icon-load',
                props: {
                  type: 'ios-loading',
                  size: 18
                }
              }),
              h('div', '上传中...')
            ])
          }
        })
        const datas = await xlsxDataMapping(target.files[0], keyMap)
        await request('/performance/pfm_hospitalization/batch', 'post', {
          pfmMonthId: this.pfmMonthId,
          datas: datas.slice(0, -1)
        })
        this.$Spin.hide();
        this.$Message.success('导入完成')
        this.loadData()
      } catch (e) {
        this.$Spin.hide();
        this.$Modal.error({ title: '导入失败', content: e.message })
      }
      target.value = null
    },
    async dataSync () {
      this.loading.sync = true
      try {
        await request('/performance/pfm_hospitalization/sync', 'post', { pfmMonthId: this.pfmMonthId })
        this.loadData()
      } catch (e) {
        console.error(e)
      }
      this.loading.sync = false
    },
    async dataTruncate () {
      this.loading.sync = true
      try {
        await request('/performance/pfm_hospitalization/clear', 'post', { pfmMonthId: this.pfmMonthId })
        this.loadData()
      } catch (e) {
        console.error(e)
      }
      this.loading.sync = false
    },
    onSortChange ({ column, key, order }) {
      this.query.sort = { key: column.sortKey || key, type: order }
      this.loadData()
    },
    onSearch () {
      this.page.num = 1
      this.loadData()
    },
    onPageChange (num) {
      this.page.num = num
      this.loadData()
    },
    transData (data) {
      return JSON.parse(JSON.stringify(data, (key, value) => {
        if ((['inTime', 'outTime'].includes(key) || value instanceof Date) && value) {
          return this.$datetime.format(value, 'Y-M-D H:I:S')
        }
        return value
      }))
    },
    onSelectionChange (e) {
      this.selectionList = e
      this.msg.batchAssign = null
      this.batch.assginPfmMonthId = null
      this.batch.show = this.selectionList.length > 0
    },
    itemDetails (item) {
      this.formData = item
      this.status.details = true
    },
    itemEdit (item) {
      this.formData = JSON.parse(JSON.stringify(item))
      this.status.drawer = true
    },
    backClick () {
      this.$emit('back')
    },
    async initMonth () {
      const pfmMonthId = this.pfmMonthId ? this.pfmMonthId : this.initPfmMonthId
      if (!pfmMonthId) {
        return this.$Message.success('请选择要初始化的月份')
      }
      try {
        this.loading.init = true
        await request('/performance/pfm_hospitalization/init_month', 'post', { pfmMonthId })
        this.loading.init = false
        this.$Message.success('初始化成功')
        this.page.num = 1
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '初始化失败', content: e.message })
        console.error(e)
        this.loading.init = false
      }
    },
    async batchDel () {
      try {
        this.loading.batch = true
        const promise = this.selectionList.map(v => {
          return request('/performance/pfm_hospitalization/' + v.id, 'delete')
        })
        await Promise.all(promise)
        this.$Message.success('已删除')
        this.loading.batch = false
        this.batch.show = false
        this.page.num = 1
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '删除失败', content: e.message })
        this.loading.batch = false
      }
    },
    async batchAssignMonth () {
      const pfmMonthId = this.batch.assginPfmMonthId
      if (!pfmMonthId) {
        return this.msg.batchAssign = '请选择要分配的月份'
      }

      const hasMonthId = this.selectionList.filter(v => v.pfmMonthId).map(v => v.name)
      if (hasMonthId.length) {
        return this.msg.batchAssign = hasMonthId.join('、') + '已分配，不可重复分配'
      }

      const ids = this.selectionList.map(v => v.id).join(',')
      try {
        this.loading.batch = true
        await request('/performance/pfm_hospitalization/to_month', 'post', { pfmMonthId, ids })
        this.loading.batch = false
        this.$Message.success('分配成功')
        this.loadData()
        this.batch.show = false
      } catch (e) {
        this.$Modal.error({ title: '分配失败', content: e.message })
        console.error(e)
        this.loading.batch = false
      }
    },
    async batchRemoveMonth () {
      try {
        this.loading.batch = true
        const promise = this.selectionList.map(v => {
          return request('/performance/pfm_hospitalization/remove_month/' + v.id, 'post')
        })
        await Promise.all(promise)
        this.$Message.success('操作成功')
        this.loading.batch = false
        this.batch.show = false
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '操作失败', content: e.message })
        this.loading.batch = false
      }
    },
    async submit () {
      this.loading.submit = true
      try {
        if (!this.formData.pfmMonthId && this.pfmMonthId) {
          this.formData.pfmMonthId = this.pfmMonthId
        }

        if (this.formData.id) {
          await request('/performance/pfm_hospitalization/' + this.formData.id, 'put', this.transData(this.formData))
        } else {
          await request('/performance/pfm_hospitalization', 'post', this.transData(this.formData))
          this.page.num = 1
        }

        this.status.drawer = false
        this.loading.submit = false
        this.$Message.success('操作成功')
        this.loadData()
      } catch (e) {
        console.error(e)
        this.loading.submit = false
      }
    },
    async itemDel (item) {
      try {
        await request('/performance/pfm_hospitalization/' + item.id, 'delete')
        this.$Message.success('删除成功')
        this.loadData()
      } catch (e) {
        this.$Message.success('删除失败')
      }
    },
    async loadData () {
      this.loading.load = true

      this.$Loading.start()
      try {
        const params = {
          pfmMonthId: this.pfmMonthId,
          pageNum: this.page.num,
          pageSize: this.page.size
        }
          
        Object.keys(this.query).filter(key => !['key', 'value', 'sort'].includes(key) && this.query[key] !== null).forEach(key => {
          params[key] = this.query[key]
        })

        if (this.query.key && this.query.value) {
          params[this.query.key] = this.query.value
        }

        if (this.query.sort && this.query.sort.key && this.query.sort.type !== 'normal') {
          params.sortKey = this.query.sort.key
          params.sortType = this.query.sort.type
        }

        let res
        if (this.$authFunsProxyNoRoute['performance.calculate.index-manage'] || this.$authFunsProxyNoRoute['performance.calculate.index-editor']) {
          res = await request('/performance/pfm_hospitalization', 'get', params)
        } else {
          res = await request('/performance/pfm_hospitalization/by_auth', 'get', params)
        }

        this.dataList = res.data
        this.page.total = res.total
        this.$Loading.finish()
      } catch (error) {
        this.$Loading.error()
      }

      this.loading.load = false
    },
    addClick () {
      this.formData = getDefault()
      this.status.drawer = true
    },
    resize () {
      this.tableHeight = this.$refs.content.offsetHeight
      this.page.size = Math.ceil((this.tableHeight - 40) / 48)
    },
    async loadUnitList () {
      if (this.$authFunsProxyNoRoute['performance.calculate.index-manage'] || this.$authFunsProxyNoRoute['performance.calculate.index-editor']) {
        this.unitList = await this.$store.dispatch('loadPfmUnitList')
      } else {
        this.unitList = await request('/performance/pfm_unit/by_auth')
      }
    },
    async loadWorkerList () {
      if (this.$authFunsProxyNoRoute['performance.calculate.index-manage'] || this.$authFunsProxyNoRoute['performance.calculate.index-editor']) {
        this.workerList = await request('/performance/pfm_worker', 'get')
      } else {
        this.workerList = await request('/performance/pfm_worker/by_auth', 'get')
      }
    }
  },
  async mounted () {
    window.addEventListener('resize', this.resize)
    this.resize()

    this.$store.dispatch('loadPfmOrgList')
    this.$store.dispatch('loadPfmMonthList')
    this.loadUnitList()
    this.loadWorkerList()
    
    this.loadData()
  },
  destroyed () {
    this.dataList = []
    this.unitList = []
    this.workerList = []
    this.selectionList = []
    window.removeEventListener('resize', this.resize)
  },
}
</script>
