<style lang="less" scoped>
  .pfm-manage-page {
    position: relative;
    overflow: auto;
    &.flex-page {
      display: flex;
      flex-direction: column;
    }
  }
  .content-page {
    flex: 1;height: 0;
  }
  .content {
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    margin: 0 10px 10px;
    height: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
</style>

<template>
  <div class="pfm-manage-page flex-page">
    <template v-if="data">
      <MonthSteps style="margin: 10px;" :status="data.status" />
      <div class="content">
        <PfmUnit
          flex
          back
          @back="back"
          :pfmMonthStatus="data.status"
          :pfmMonthId="data.id"
          :title="title"
          class="content-page"
          :mode="mode"
          v-if="page === 'unit'" />
        <PfmWorker
          flex
          back
          @back="back"
          :title="title"
          :pfmMonthStatus="data.status"
          :pfmMonthId="data.id"
          :pfmMonth="data"
          :mode="mode"
          class="content-page"
          v-else-if="page === 'worker' || page === 'divide'" />
        <PfmProject
          flex
          back
          @back="back"
          :title="title"
          :pfmMonthStatus="data.status"
          :pfmMonthId="data.id"
          :mode="mode"
          class="content-page"
          v-else-if="page === 'project'" />
        <PfmConsume
          flex
          back
          @back="back"
          :title="title"
          :pfmMonthStatus="data.status"
          :pfmMonthId="data.id"
          :mode="mode"
          class="content-page"
          v-else-if="page === 'consume'" />
        <PfmHospitalization
          flex
          back
          @back="back"
          :title="title"
          :pfmMonthStatus="data.status"
          :pfmMonthId="data.id"
          :mode="mode"
          class="content-page"
          v-else-if="page === 'hospitalization'" />
        <PfmWorkerValue
          flex
          back
          @back="back"
          :pfmMonthStatus="data.status"
          :pfmMonthId="data.id"
          title="重点项目绩效"
          :mode="mode"
          valueKey="singleValue"
          class="content-page"
          v-else-if="page === 'small'" />
        <PfmWorkerValue
          flex
          back
          @back="back"
          :pfmMonthStatus="data.status"
          :pfmMonthId="data.id"
          title="平均奖"
          :mode="mode"
          valueKey="avgValue"
          :pfmMonthData="data"
          class="content-page"
          v-else-if="page === 'avgValue'" />
        <PfmWorkerValue
          flex
          back
          @back="back"
          :pfmMonthStatus="data.status"
          :pfmMonthId="data.id"
          title="分配明细"
          :mode="mode"
          valueKey="unitValue"
          class="content-page"
          v-else-if="page === 'unitValue'" />
        <PfmBig
          flex
          back
          @back="back"
          :pfmMonthStatus="data.status"
          :pfmMonthId="data.id"
          :title="title"
          class="content-page"
          :mode="mode"
          v-else-if="page === 'big' || page === 'status'" />
      </div>
    </template>
  </div>
</template>

<script>
import { request } from '@/api'
import MonthSteps from '../items/steps.vue'
import PfmWorker from './worker.vue'
import PfmUnit from './unit.vue'
import PfmProject from './project.vue'
import PfmConsume from './consume.vue'
import PfmWorkerValue from './value.vue'
import PfmBig from './big.vue'
import PfmHospitalization from './hospitalization.vue'

export default {
  components: { MonthSteps, PfmWorker, PfmUnit, PfmProject, PfmConsume, PfmHospitalization, PfmWorkerValue, PfmBig },
  props: {
    page: { type: String, default: null },
    title: { type: String, default: null },
    mode: { type: String, default: null },
  },
  data () {
    return {
      data: null
    }
  },
  computed: {
    monthId () {
      return this.$route.query.monthId
    }
  },
  watch: {
    monthId () {
      if (this.$route.name.indexOf('performance.calculate.details') > -1) {
        this.loadData()
      }
    }
  },
  methods: {
    back () {
      this.$router.back()
    },
    async loadData () {
      try {
        this.$Loading.start()
        const res = await request('/performance/pfm_month?id=' + this.monthId)
        if (res.length) {
          this.data = res[0]
        } else {
          this.$Message.error('数据不存在')
          throw new Error('数据不存在')
        }
        this.$Loading.finish()
      } catch (e) {
        this.$Loading.error()
        this.$router.back()
      }
    },
  },
  mounted () {
    this.loadData()
  },
}
</script>