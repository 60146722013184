<style scoped>
  .unit-value {
    display: flex;
    flex-direction: column;
  }
</style>

<template>
  <div class="unit-value">
    <fm-input-number v-model="value" placehodler="请输入分配值" />
    <Button style="margin-top: 10px;" type="primary" @click="submit" :loading="loading" long>确定</Button>
  </div>
</template>

<script>
import { request } from '@/api'

export default {
  props: {
    data: { type: Object, default: null },
    valueKey: { type: String, default: null }
  },
  data () {
    return {
      loading: false,
      value: this.data ? this.data[this.valueKey] : null
    }
  },
  computed: {
    hisValue () {
      return this.data ? this.data[this.valueKey] : null
    }
  },
  watch: {
    hisValue () {
      this.value = this.hisValue
    }
  },
  methods: {
    async submit () {
      if (this.value !== 0 && !this.value) {
        return
      }
      try {
        let parm = {}
        parm[this.valueKey] = this.value
        this.loading = true
        await request('/performance/pfm_worker_month/unit_value/' + this.data.id, 'post', parm)
        this.loading = false
        this.$Message.success('操作成功')
        this.$emit('submit', this.value)
      } catch (e) {
        this.loading = false
        console.error(e)
        this.$Modal.error({ title: '操作失败', content: e.message })
      }
    },
  },
}
</script>