<style scoped lang="less">
  .pfm-month-worker-small {
    &.flex {
      display: flex;
      flex-direction: column;
      .worker-list {
        height: 0;
        flex: 1;
      }
    }
  }
  .title {
    margin-bottom: 10px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .worker-list {
    padding: 5px;
    display: flex;
    overflow: auto;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    .wrap {
      width: 20%;
      padding: 5px;
    }
    .worker {
      padding: 10px;
      border: 1px solid #eee;
      border-radius: 5px;
      transition: all .3s;
      &:hover {
        border-color: #2d8cf0;
      }
    }
    .value {
      font-size: 14px;
      color: #515a6e;
    }
    .info {
      align-items: center;
      justify-content: space-between;
      display: flex;
      .name {
        font-size: 16px;
      }
      .value {
        font-size: 14px;
        color: #000;
      }
      .org {
        font-size: 12px;
        color: #657180;
      }
      & + .info {
        margin-top: 5px;
      }
    }
  }
</style>

<style>
  .pfm-month-worker-small .ivu-tag {
    border-color: transparent;
    margin: 0;
  }
</style>

<template>
  <div class="pfm-month-worker-small" :class="{ flex }">
    <fm-title style="margin: 0 10px;" :title-text="title" :note-text="noteText" />
    <div class="title">
      <fm-form inline style="flex-wrap: nowrap;">
        <fm-form-item style="margin-bottom: 0;">
          <i-input placeholder="请输入搜索内容" clearable v-model="query.value">
            <Select slot="prepend" style="width: 100px" v-model="query.key">
              <Option value="pfmWorkerName">员工</Option>
              <Option value="idNo">身份证</Option>
              <Option value="phone">手机</Option>
            </Select>
          </i-input>
        </fm-form-item>
        <fm-form-item style="margin-bottom: 0;" label="科室">
          <Select style="max-width: 120px;" filterable clearable v-model="query.pfmOrgId">
            <Option v-for="item in orgList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.hisCode }}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item style="margin-bottom: 0;" label="考核单元" v-if="!currentPfmUnitId">
          <Select style="max-width: 120px;" filterable clearable v-model="query.pfmUnitId">
            <Option v-for="item in unitList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.code }}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item style="margin-bottom: 0;" label="职务">
          <Select style="max-width: 120px;" filterable clearable v-model="query.pfmPositionId">
            <Option v-for="item in positionList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.hisCode }}</Option>
          </Select>
        </fm-form-item>
      </fm-form>
      <div style="display: flex;flex-wrap: nowrap;">
        <Button style="margin-right: 10px;" icon="ios-arrow-back" v-if="back" @click="backClick">返回</Button>
        <Button style="margin-right: 10px;" icon="md-refresh-circle" :loading="loading.load" @click="loadData">刷新</Button>
      </div>
    </div>
    <div class="scrollbar worker-list" ref="content">
      <template v-for="item in showDataList">
        <div class="wrap" :key="item.id">
          <div class="worker">
            <div class="info">
              <span class="name">{{item.pfmWorkerName}}</span>
              <Tag color="blue">{{item.pfmPositionName || '-'}}</Tag>
            </div>
            <div class="info">
              <span class="org">{{item.pfmOrgName}}</span>
              <span class="value">{{item[valueKey] !== null ? item[valueKey] : '-'}}</span>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { request } from '@/api'

export default {
  props: {
    mode: { type: String, default: 'view' },
    back: { type: Boolean, default: false },
    flex: { type: Boolean, default: false },
    inner: { type: Boolean, default: false },
    title: { type: String, default: '重点项目绩效' },
    valueKey: { type: String, default: 'singleValue' },
    pfmUnitId: { type: Number, default: null },
    pfmMonthId: { type: Number, default: null },
    pfmMonthData: { type: Object, default: null },
    pfmMonthStatus: { type: String, default: 'plan' },
  },
  data () {
    return {
      dataList: [],
      unitList: [],
      query: {
        key: 'pfmWorkerName',
        value: null,
        pfmOrgId: null,
        pfmUnitId: null,
        pfmPositionId: null,
        pfmPositionTypeText: null
      },
      loading: {
        load: false
      }
    }
  },
  computed: {
    noteText () {
      if (this.valueKey === 'avgValue' && this.pfmMonthData) {
        return '共' + this.dataList.length + '人 - 平均奖: ' + this.pfmMonthData.avgPfm
      } else {
        return '共' + this.dataList.length + '人'
      }
    },
    currentPfmUnitId () {
      if (this.$route.query.pfmUnitId && this.pfmUnitId && this.$route.query.pfmUnitId !== this.pfmUnitId) {
        console.error('同时绑定了路由pfmUnitId和组件pfmUnitId值')
      }
      const pfmUnitId = this.pfmUnitId || this.$route.query.pfmUnitId
      return pfmUnitId ? Number(pfmUnitId) : null
    },
    positionType () {
      return this.$store.getters.pfmPositionType
    },
    orgList () {
      return this.$store.getters.pfmOrgList
    },
    positionList () {
      return this.$store.getters.pfmPositionList
    },
    showDataList () {
      const key = this.query.key
      const value = this.query.value
      const pfmOrgId = this.query.pfmOrgId
      const pfmUnitId = this.currentPfmUnitId ? this.currentPfmUnitId : this.query.pfmUnitId
      const pfmPositionId = this.query.pfmPositionId
      const pfmPositionTypeText = this.query.pfmPositionTypeText
      return this.dataList.filter(item => {
        if (key && value && item[key] && String(item[key]).indexOf(value) === -1) {
          return false
        }
        if (pfmOrgId && item.pfmOrgId !== pfmOrgId) {return false;}
        if (pfmUnitId && item.pfmUnitId !== pfmUnitId) {return false;}
        if (pfmPositionId && item.pfmPositionId !== pfmPositionId) {return false;}
        if (pfmPositionTypeText && item.pfmPositionTypeText && item.pfmPositionTypeText.indexOf(pfmPositionTypeText) < 0) {return false;}
        return true
      })
    }
  },
  watch: {
    mode () {
      this.loadUnitList()
      this.loadWorkerList()
      this.loadData()
    }
  },
  methods: {
    backClick () {
      this.$emit('back')
    },
    async loadData () {
      this.loading.load = true
      const params = { pfmMonthId: this.pfmMonthId }

      if (this.currentPfmUnitId) {
        params.pfmUnitId = this.currentPfmUnitId
      }

      if (this.valueKey === 'avgValue') {
        params.isQt = 1
      }

      if (this.valueKey === 'singleValue') {
        params.isYw = 1
      }

      this.$Loading.start()
      let dataList
      if (this.$authFunsProxyNoRoute['performance.calculate.index-manage']) {
        dataList = await request('/performance/pfm_worker_month', 'get', params)
      } else if (this.$authFunsProxyNoRoute['performance.calculate.index-operation'] || this.$authFunsProxyNoRoute['performance.calculate.index-view']) {
        dataList = await request('/performance/pfm_worker_month/by_auth', 'get', params)
      }

      dataList.forEach(item => {
        const type = this.positionType.find(v => v.value === item.pfmPositionType)
        item.pfmPositionTypeText = type ? type.label : item.pfmPositionType
      })

      this.dataList = dataList
      this.loading.load = false
      this.$Loading.finish()
    },
    async loadUnitList () {
      let unitList = []
      if (this.$authFunsProxyNoRoute['performance.calculate.index-manage']) {
        unitList = await this.$store.dispatch('loadPfmUnitList')
      } else if (this.$authFunsProxyNoRoute['performance.calculate.index-operation'] || this.$authFunsProxyNoRoute['performance.calculate.index-view']) {
        unitList = await request('/performance/pfm_unit/by_auth')
      }
      this.unitList = unitList
    }
  },
  async mounted () {
    this.$store.dispatch('loadPfmOrgList')
    this.$store.dispatch('loadPfmMonthList')
    this.$store.dispatch('loadPfmPositionList')

    this.loadUnitList()
    this.loadData()
  },
  destroyed () {
    this.dataList = []
  },
}
</script>
