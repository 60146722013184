<style scoped lang="less">
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EEE;
    padding-bottom: 10px;
    .unit-name {
      font-weight: 800;
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 0;
    }
  }
  .fields {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .actions {
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .unit-list {
    align-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    box-sizing: border-box;
    .wrap {
      box-sizing: border-box;
      width: 25%;
      padding: 5px;
    }
    .item {
      cursor: pointer;
      padding: 10px;
      box-sizing: border-box;
      border: 1px solid #EEE;
      border-radius: 5px;
    }
  }
  .values {
    padding: 10px 0;
    box-sizing: border-box;
  }
  .fields {
    display: flex;
    align-items: center;
    & + .fields {
      margin-top: 10px;
    }
    .carx {
      display: flex;
      align-items: center;
      .iconfont {
        margin-right: 5px;
        font-size: 18px;
      }
      .value {
        font-size: 15px;
      }
      .unit {
        margin-left: 5px;
        font-size: 12px;
        color: #999;
      }
    }
  }
  .pfm-month-unit {
    &.flex {
      display: flex;
      flex-direction: column;
      .unit-list {
        overflow: auto;
        height: 0;
        flex: 1;
      }
    }
  }
  .title {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .unit-type {
    font-size: 12px;
    font-weight: normal;
    color: #657180;
  }
</style>

<style>
  .pfm-month-unit .fm-loading-fix {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
</style>

<template>
  <div class="pfm-month-unit-big" :class="{ flex }">
    <template>
      <div class="title">
        <fm-title :title-text="title">
          <fm-form inline align-alone label-align="left">
            <fm-form-item style="margin-bottom: 0;" label="月度绩效" v-if="!pfmMonthId">
              <Select placeholder="请选择月度绩效" filterable clearable v-model="query.pfmMonthId">
                <Option v-for="item in monthList" :key="item.id" :value="item.id">{{item.month}} - {{item.statusText}}</Option>
              </Select>
            </fm-form-item>
            <fm-form-item style="margin-bottom: 0;" label="考核单元">
              <Select placeholder="请选择考核单元" filterable clearable v-model="query.pfmUnitId">
                <Option v-for="item in unitList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.code }}</Option>
              </Select>
            </fm-form-item>
          </fm-form>
        </fm-title>
        <div>
          <Button style="margin-right: 10px;" icon="ios-arrow-back" v-if="back" @click="backClick">返回</Button>
          <Button style="margin-right: 10px;" icon="md-refresh-circle" :loading="loading.load" @click="loadData">刷新</Button>
        </div>
      </div>
      <div class="unit-list scrollbar" v-loadingx="loading.load">
        <div class="wrap" v-for="item in showDataList" :key="item.id">
          <div class="item" @click="itemClick(item)">
            <div class="info">
              <div class="unit-name">{{item.pfmUnitName || '考核单元不存在'}}<span class="unit-type"> - {{item.typeText}}</span></div>
              <Tag v-if="item.status === 'doing'" color="purple">分配中</Tag>
              <Tag v-else-if="item.status === 'end'" color="blue">已完成</Tag>
              <Tag v-else color="orange">等待</Tag>
            </div>

            <div class="values">
              <div class="fields">
                <Tooltip content="普通工作量绩效总数" placement="top-start">
                  <div class="carx">
                    <i class="iconfont icon-fuwuxunhuanguanli_o"></i>
                    <span class="value">{{item.unitValueTotal !== null ? item.unitValueTotal : '-'}}</span>
                  </div>
                </Tooltip>
                <Tooltip content="总人数" placement="top-start">
                  <div class="carx">
                    <i class="iconfont icon-zongrenshu"></i>
                    <span class="value">{{item.workerTotal !== null ? item.workerTotal : '-'}}</span>
                    <span class="unit">人</span>
                  </div>
                </Tooltip>
                <Tooltip content="已分配的大循环绩效总数" placement="top-start">
                  <div class="carx">
                    <i class="iconfont icon-qitazhichu"></i>
                    <span class="value">{{item.divideUnitValueTotal !== null ? item.divideUnitValueTotal : '-'}}</span>
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { request } from '@/api'

export default {
  props: {
    mode: { type: String, default: 'view' },
    back: { type: Boolean, default: false },
    flex: { type: Boolean, default: false },
    inner: { type: Boolean, default: false },
    title: { type: String, default: '普通工作量绩效' },
    pfmMonthId: { type: Number, default: null },
    pfmMonthStatus: { type: String, default: 'plan' }
  },
  data () {
    return {
      dataList: [],
      unitList: [],
      monthList: [],
      chooseData: null,
      status: {
        drawer: false,
        divide: false
      },
      loading: {
        load: false,
        submit: false,
        init: false
      },
      query: {
        pfmMonthId: null,
        pfmUnitId: null
      }
    }
  },
  computed: {
    showDataList () {
      const pfmMonthId = this.query.pfmMonthId
      const pfmUnitId = this.query.pfmUnitId
      return this.dataList.filter(item => {
        if (pfmMonthId && item.pfmMonthId !== pfmMonthId) {
          return false
        }
        if (pfmUnitId && item.pfmUnitId !== pfmUnitId) {
          return false
        }
        return true
      })
    },
    pfmUnitType () {
      return this.$store.getters.pfmUnitType
    },
  },
  watch: {
    pfmMonthId () {
      this.query.pfmMonthId = null
    },
    async mode () {
      this.loadData()
    }
  },
  methods: {
    itemClick (item) {
      console.log({
        name: 'performance.calculate.details.worker.unitValue',
        query: { pfmUnitId: item.pfmUnitId, monthId: item.pfmMonthId }
      })
      this.$router.push({
        name: 'performance.calculate.details.worker.unitValue',
        query: { pfmUnitId: item.pfmUnitId, monthId: item.pfmMonthId }
      })
    },
    backClick () {
      this.$emit('back')
    },
    async loadData () {
      this.loading.load = true
      await this.loadPfmUnitList()
      let dataList = []
      if (this.$authFunsProxyNoRoute['performance.calculate.index-manage']) {
        dataList = await request('/performance/pfm_unit_month', 'get', { pfmMonthId: this.pfmMonthId})
      } else {
        dataList = await request('/performance/pfm_unit_month/by_auth', 'get', { pfmMonthId: this.pfmMonthId})
      }
      const userId = this.$store.getters.userInfo.id
      const pfmUnitType = this.pfmUnitType
      dataList.forEach(item => {
        const manageUserIds = (item.manageUserIds || '').split(',').map(v => Number(v))
        item.authDivide = manageUserIds.includes(userId)
        item.typeText = (pfmUnitType.find(v => v.value === item.type) || { label: '未知' }).label
      })
      this.dataList = dataList
      this.loading.load = false
    },
    async loadMonthList () {
      if (this.pfmMonthId) {
        return this.monthList = []
      }
      this.monthList = await this.$store.dispatch('loadPfmMonthList')
    },
    async loadPfmUnitList () {
      let unitList = []
      if (this.mode === 'manage') {
        unitList = await this.$store.dispatch('loadPfmUnitList')
      } else {
        unitList = await request('/performance/pfm_unit/by_auth', 'get')
      }
      this.unitList = unitList
    }
  },
  async mounted () {
    this.loadMonthList()
    this.loadData()
  },
  destroyed () {
    this.unitList = []
    this.dataList = []
    this.monthList = []
  },
}
</script>
