<style lang="less" scoped>
  .pfm-bill-details {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .item-list {
    flex: 1;
    height: 0;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    padding: 5px;
    align-content: flex-start;
    .wrap {
      width: 50%;
      padding: 5px;
    }
    .item {
      border: 1px solid #EEE;
      border-radius: 5px;
      position: relative;
      padding: 10px;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      &:hover {
        .name, .type {
          color: #2d8cf0;
        }
      }
    }
    .name {
      flex: 1;
    }
    .name, .type {
      transition: all .3s;
      cursor: pointer;
    }
    .right {
      display: flex;
      align-items: center;
    }
  }
</style>

<template>
  <div class="pfm-bill-details">
    <fm-form v-if="pfmMonthStatus === 'plan' && mode === 'manage'">
      <fm-form-item label="人员">
        <Select placeholder="请选择人员" filterable clearable v-model="formData.pfmWorkerId">
          <Option v-for="item in workerList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.hisCode }}</Option>
        </Select>
      </fm-form-item>
      <fm-form-item label="类型">
        <Select v-model="formData.type" filterable>
          <Option v-for="item in typeList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
      </fm-form-item>
      <fm-form-item>
        <Button type="primary" :loading="loading.submit" @click="submit">{{formData.id ? '确定修改' : '确定新增'}}</Button>
        <Button style="margin-left: 10px;" v-if="formData.id" :loading="loading.submit" @click="cancel">取消</Button>
      </fm-form-item>
    </fm-form>
    <Divider>人员列表 - {{dataList.length}}人</Divider>
    <div class="item-list scrollbar smallbar">
      <div class="wrap" v-for="item in dataList" :key="item.id">
        <div class="item">
          <span @click="itemEdit(item)" class="name">{{item.pfmWorkerName}}</span>
          <div class="right" v-if="mode === 'manage'">
            <span @click="itemEdit(item)" class="type">{{item.typeText}}</span>
            <span class="del" v-if="pfmMonthStatus === 'plan'">
              <Poptip transfer confirm @on-ok="itemDel(item)" title="确定删除吗？">
                <Button type="text" size="small" icon="md-close-circle" shape="circle"></Button>
              </Poptip>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from '@/api'

function getDefault () {
  return {
    id: null,
    pfmWorkerId: null,
    type: null,
    pfmProjectBillId: null
  }
}

export default {
  props: {
    mode: { type: String, default: 'view' },
    pfmMonthStatus: { type: String, default: 'plan' },
    pfmProjectBillId: { type: Number, default: null },
    singlePfmConfig: { type: Array, default: () => [] }
  },
  data () {
    return {
      formData: getDefault(),
      workerList: [],
      dataList: [],
      loading: {
        load: false,
        submit: false
      }
    }
  },
  computed: {
    typeList () {
      return this.$store.getters.pfmSingleType
    }
  },
  watch: {
    pfmProjectBillId () {
      this.loadData()
      this.formData = getDefault()
    },
    mode () {
      this.loadWorkerList()
    }
  },
  methods: {
    cancel () {
      this.formData = getDefault()
    },
    itemEdit(item) {
      if (this.pfmMonthStatus !== 'plan' || this.mode !== 'manage') {
        return
      }
      this.formData = JSON.parse(JSON.stringify(item))
    },
    async itemDel (item) {
      try {
        await request('/performance/pfm_project_bill/detail/' + item.id, 'delete')
        this.$Message.success('已删除')
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '删除失败', content: e.message })
      }
    },
    async submit () {
      try {
        this.loading.submit = true
        if (this.formData.id) {
          await request('/performance/pfm_project_bill/detail/' + this.formData.id, 'put', this.formData)
        } else {
          this.formData.pfmProjectBillId = this.pfmProjectBillId
          await request('/performance/pfm_project_bill/detail', 'post', this.formData)
        }
        this.loadData()
        this.loading.submit = false
        this.formData = getDefault()
      } catch (e) {
        this.$Modal.error({ title: '添加失败', content: e.message })
        this.loading.submit = false
      }
    },
    async loadData () {
      this.loading.load = true
      this.$Loading.start()
      const dataList = await request('/performance/pfm_project_bill/detail', 'get', { pfmProjectBillId: this.pfmProjectBillId })
      dataList.forEach(item => {
        const type = this.typeList.find(v => v.value === item.type)
        item.typeText = type ? type.label : item.type
      })
      this.dataList = dataList
      this.$Loading.finish()
      this.loading.load = false
    },
    async loadWorkerList () {
      if (this.mode === 'manage' && this.pfmMonthStatus === 'plan') {
        this.workerList = await request('/performance/pfm_worker', 'get')
      } else {
        this.workerList = await request('/performance/pfm_worker/by_auth', 'get')
      }
    }
  },
  mounted () {
    this.loadWorkerList()
    this.loadData()
  },
}
</script>