<style>
  .month-unit-form .fm-input-number {
    display: flex;
  }
</style>

<template>
  <fm-form class="month-unit-form" label-alone label-align="left">
    <fm-form-item label="月度绩效" v-if="!pfmMonthId">
      <Select placeholder="请选择月度绩效" filterable clearable v-model="formData.pfmMonthId">
        <Option v-for="item in monthList" :key="item.id" :value="item.id">{{item.month}} - {{item.statusText}}</Option>
      </Select>
    </fm-form-item>
    <fm-form-item label="考核单元">
      <Select placeholder="请选择考核单元" :disabled="formData.id !== null" @on-change="onUnitChange" filterable clearable v-model="formData.pfmUnitId">
        <Option v-for="item in unitList" :value="item.id" :key="item.id">{{ item.name }}</Option>
      </Select>
    </fm-form-item>
    <fm-form-item label="类型">
      <Select placeholder="请选择类型" :disabled="formData.id !== null" filterable clearable v-model="formData.type">
        <Option v-for="item in pfmUnitType" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
    </fm-form-item>
    <fm-form-item v-for="item in defaultConfig.filter(v => v.type === 'input' && v.status === 'enable')" :label="item.valueLabel" :key="item.id">
      <fm-input-number v-model="formData[item.valueKey]" :placeholder="item.valueLabel" />
    </fm-form-item>
    <fm-form-item>
      <div style="display: flex;">
        <Button :loading="loading" style="flex: 1;" @click="cancel" type="default">取消</Button>
        <Button :loading="loading" style="margin-left: 10px;flex: 1;" @click="submit" type="info">确定{{(formData.id ? '修改' : '新增')}}</Button>
      </div>
    </fm-form-item>
  </fm-form>
</template>

<script>
function getDefault (defaultConfig) {
  let obj = {}
  defaultConfig.filter(v => v.status === 'enable').forEach(v => {
    obj[v.valueKey] = null
  })
  return Object.assign({
    id: null,
    pfmUnitId: null,
    pfmMonthId: null,
    type: null
  }, obj)
}

export default {
  props: {
    data: { type: Object, default: null },
    loading: { type: Boolean, default: false },
    unitList: { type: Array, default: () => [] },
    monthList: { type: Array, default: () => [] },
    pfmMonthId: { type: Number, default: null },
    defaultConfig: { type: Array, default: () => [] }
  },
  data () {
    return {
      formData: this.data ? JSON.parse(JSON.stringify(this.data)) : getDefault(this.defaultConfig),
    }
  },
  computed: {
    pfmUnitType () {
      return this.$store.getters.pfmUnitType
    },
    userList () {
      return this.$store.getters.userList
    },
  },
  watch: {
    data () {
      this.formData = this.data ? JSON.parse(JSON.stringify(this.data)) : getDefault(this.defaultConfig)
    }
  },
  methods: {
    onUnitChange () {
      if (this.formData.pfmUnitId) {
        const unit = this.unitList.find(v => v.id === this.formData.pfmUnitId)
        if (unit) {
          this.formData.type = unit.type
        }
      }
    },
    cancel () {
      this.$emit('cancel')
    },
    submit () {
      if (!this.formData.pfmMonthId && this.pfmMonthId) {
        this.formData.pfmMonthId = this.pfmMonthId
      }
      if (!this.formData.pfmMonthId) {
        return this.$Message.error('请选择月度绩效')
      }
      if (!this.formData.pfmUnitId) {
        return this.$Message.error('请选择考核单元')
      }
      if (!this.formData.type) {
        return this.$Message.error('请选择类型')
      }
      this.$emit('submit', JSON.parse(JSON.stringify(this.formData)))
    }
  }
}
</script>