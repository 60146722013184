<style scoped lang="less">
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EEE;
    padding-bottom: 10px;
    .unit-name {
      font-weight: 800;
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 0;
    }
  }
  .unit-list {
    align-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
  }
  .pfm-month-unit {
    &.flex {
      display: flex;
      flex-direction: column;
      .unit-list {
        overflow: auto;
        height: 0;
        flex: 1;
      }
    }
  }
  .title {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title-note {
      height: 100%;
      display: flex;
      align-items: center;
      margin-left: 20px;
    }
  }
</style>

<style>
  .pfm-month-unit .fm-loading-fix {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
</style>

<template>
  <div class="pfm-month-unit" :class="{ flex }">
    <template>
      <div class="title">
        <fm-title :title-text="title">
          <fm-form inline align-alone label-align="left">
            <fm-form-item style="margin-bottom: 0;" label="月度绩效" v-if="!pfmMonthId">
              <Select placeholder="请选择月度绩效" filterable clearable v-model="query.pfmMonthId">
                <Option v-for="item in monthList" :key="item.id" :value="item.id">{{item.month}} - {{item.statusText}}</Option>
              </Select>
            </fm-form-item>
            <fm-form-item style="margin-bottom: 0;" label="考核单元">
              <Select placeholder="请选择考核单元" filterable clearable v-model="query.pfmUnitId">
                <Option v-for="item in unitList" :value="item.id" :key="item.id">{{ item.name }}</Option>
              </Select>
            </fm-form-item>
          </fm-form>
          <div class="title-note" v-if="mode === 'operation'">
            <span>请各科主任在20号24点完成科室绩效分配并将结果上传系统</span>
          </div>
        </fm-title>
        <div>
          <Button style="margin-right: 10px;" icon="ios-arrow-back" v-if="back" @click="backClick">返回</Button>
          <Button style="margin-right: 10px;" icon="md-refresh-circle" :loading="loading.load" @click="loadData">刷新</Button>
          <template v-if="pfmMonthStatus === 'plan' && mode === 'manage'">
            <Button style="margin-right: 10px;" icon="md-paper" :loading="loading.init" type="primary" @click="initMonthUnit">初始化全部考核单元</Button>
            <Poptip style="margin-right: 10px;" confirm @on-ok="dataSync" title="确定集成吗？">
              <Button :loading="loading.sync" type="warning" icon="md-sync">集成平台设置部分指标</Button>
            </Poptip>
            <Button icon="md-add-circle" @click="status.drawer = true; chooseData = null" type="primary">新增</Button>
          </template>
        </div>
      </div>
      <div class="unit-list" ref="content">
        <Table ref="table" :loading="loading.load" :data="showDataList" :columns="columns" :height="tableHeight">
          <template slot-scope="{ row }" slot="status">
            <Tag v-if="row.status === 'doing'" color="purple">分配中</Tag>
            <Tag v-else-if="row.status === 'end'" color="blue">已完成</Tag>
            <Tag v-else color="orange">等待</Tag>
          </template>
          <template slot-scope="{ row }" slot="manage-action">
            <Poptip transfer confirm @on-ok="itemDel(row)" title="确定删除吗？">
              <Button icon="md-trash" size="small" type="text">删除</Button>
            </Poptip>
            <Button @click="itemEdit(row)" icon="ios-brush" size="small" type="text">修改</Button>
          </template>
          <template slot-scope="{ row }" slot="operation-action">
            <template v-if="row.authDivide">
              <Poptip transfer v-if="row.status !== 'end'" confirm @on-ok="itemDivideSubmit(row, 'submit')" title="确定提交吗？">
                <Button icon="md-checkmark-circle-outline" type="text">分配提交</Button>
              </Poptip>
              <Poptip transfer v-else confirm @on-ok="itemDivideSubmit(row, 'back')" title="确定撤回吗？">
                <Button icon="ios-undo" type="text">撤回提交</Button>
              </Poptip>
              <Button v-if="row.status !== 'end'" icon="ios-expand" type="text" @click="itemDivide(row)">分配</Button>
              <Button icon="ios-document-outline" type="text" @click="openFile(row)">附件</Button>
            </template>
            <Button type="text" disabled v-else>无权限</Button>
          </template>
        </Table>
      </div>
      <fm-modal title="附件" :mask-closable="false" :value="file.open" @cancel="file.open = false" width="600px">
        <file-manage
          v-if="file.open"
          :file-data-ids="file.ids"
          @addFile="addFile"
          :funs="file.funs"
          @updateSort="updateFileSort"
          :sortable="file.funs ? file.funs.upload : false"
          @delFile="delFile"></file-manage>
      </fm-modal>
      <Drawer draggable :mask-closable="false" :mask="!inner" :inner="inner" :transfer="!inner" :title="chooseData && chooseData.id ? '修改' : '新增'" width="40" placement="right" closable v-model="status.drawer">
        <UnitForm
          :data="chooseData"
          :unitList="unitList"
          :monthList="monthList"
          :pfmMonthId="pfmMonthId"
          :loading="loading.submit"
          :defaultConfig="defaultConfig"
          v-if="status.drawer"
          @submit="submit"
          @cancel="status.drawer = false" />
      </Drawer>
    </template>
  </div>
</template>

<script>
import { request } from '@/api'
import UnitForm from './form/unit.vue'
import FileManage from '@/components/base/FileManage'

export default {
  components: { UnitForm, FileManage },
  props: {
    mode: { type: String, default: 'view' },
    back: { type: Boolean, default: false },
    flex: { type: Boolean, default: false },
    inner: { type: Boolean, default: false },
    title: { type: String, default: '考核单元配置' },
    pfmMonthId: { type: Number, default: null },
    pfmMonthStatus: { type: String, default: 'plan' }
  },
  data () {
    return {
      dataList: [],
      unitList: [],
      monthList: [],
      chooseData: null,
      defaultConfig: [],
      file: {
        id: null,
        open: false,
        ids: [],
        funs: {}
      },
      status: {
        drawer: false
      },
      loading: {
        load: false,
        submit: false,
        init: false,
        sync: false
      },
      query: {
        pfmMonthId: null,
        pfmUnitId: null
      },
      tableHeight: null
    }
  },
  computed: {
    showDataList () {
      const pfmMonthId = this.query.pfmMonthId
      const pfmUnitId = this.query.pfmUnitId
      return this.dataList.filter(item => {
        if (pfmMonthId && item.pfmMonthId !== pfmMonthId) {
          return false
        }
        if (pfmUnitId && item.pfmUnitId !== pfmUnitId) {
          return false
        }
        return true
      })
    },
    pfmUnitType () {
      return this.$store.getters.pfmUnitType
    },
    columns () {
      const defaultConfig = this.defaultConfig
      let columns = [
        { key: 'pfmUnitName', title: '名称', fixed: 'left', width: 120, ellipsis: true, tooltip: true },
        { key: 'typeText', title: '类型', fixed: 'left', width: 100, ellipsis: true, tooltip: true },
        { key: 'status', title: '状态', fixed: 'left', width: 90, ellipsis: true, tooltip: true, slot: 'status' },
        ...defaultConfig.filter(v => v.status === 'enable').map(item => {
          return {
            key: item.valueKey, title: item.valueLabel,
            sortable: true, width: 170, ellipsis: true, tooltip: true
          }
        })
      ]

      if (this.mode === 'manage' && this.pfmMonthStatus === 'plan') {
        return [
          ...columns,
          { key: 'action', title: '操作', fixed: 'right', width: 180, slot: 'manage-action' },
        ]
      } else if (this.mode === 'operation' && this.pfmMonthStatus === 'divide') {
        return [
          ...columns,
          { key: 'action', title: '操作', fixed: 'right', width: 320, slot: 'operation-action' },
        ]
      }
      return columns
    }
  },
  watch: {
    pfmMonthId () {
      this.query.pfmMonthId = null
      this.loadDefaultConfig()
      this.loadData()
    },
    async mode () {
      this.loadData()
    }
  },
  methods: {
    async updateFileSort (fileIds) {
      this.file.ids = fileIds
      await request('/performance/pfm_unit_month/file_ids/' + this.file.id, 'post', { fileIds: this.file.ids.join(',') })
      this.loadData()
    },
    async addFile (data) {
      this.file.ids.push(data.id)
      await request('/performance/pfm_unit_month/file_ids/' + this.file.id, 'post', { fileIds: this.file.ids.join(',') })
      this.loadData()
    },
    async delFile (data) {
      this.file.ids = this.file.ids.filter(v => v !== data.id)
      await request('/performance/pfm_unit_month/file_ids/' + this.file.id, 'post', { fileIds: this.file.ids.join(',') })
      this.loadData()
    },
    async dataSync () {
      this.loading.sync = true
      try {
        await request('/performance/pfm_unit_month/sync', 'post', { pfmMonthId: this.pfmMonthId })
        this.loadData()
      } catch (e) {
        console.error(e)
      }
      this.loading.sync = false
    },
    async loadDefaultConfig () {
      this.defaultConfig = await request('/performance/pfm_month/unit_config/' + this.pfmMonthId)
    },
    itemDivide (item) {
      this.$router.push({
        name: 'performance.calculate.details.worker.divide',
        query: {
          monthId: this.pfmMonthId,
          pfmUnitId: item.pfmUnitId
        }
      })
    },
    openFile (item) {
      this.file = {
        id: item.id,
        open: true,
        ids: item.fileIds ? item.fileIds.split(',').map(v => Number(v)) : [],
        funs: {
          get: true,
          upload: item.status !== 'end',
          del: item.status !== 'end'
        }
      }
    },
    itemEdit (item) {
      this.chooseData = item
      this.status.drawer = true
    },
    backClick () {
      this.$emit('back')
    },
    async initMonthUnit () {
      try {
        this.loading.init = true
        await request('/performance/pfm_unit_month/init', 'post', { pfmMonthId: this.pfmMonthId, initDefault: 1 })
        this.loading.init = false
        this.$Message.success('初始化成功')
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '初始化失败', content: e.message })
        console.error(e)
        this.loading.init = false
      }
    },
    async submit (data) {
      try {
        this.loading.submit = true
        if (data.id) {
          await request('/performance/pfm_unit_month/' + data.id, 'put', data)
        } else {
          await request('/performance/pfm_unit_month', 'post', data)
        }
        this.loading.submit = false
        this.status.drawer = false
        this.$Message.success('操作成功')
        this.loadData()
      } catch (error) {
        this.loading.submit = false
        console.error(error)
      }
    },
    async itemDel (item) {
      try {
        await request('/performance/pfm_unit_month/' + item.id, 'delete')
        this.$Message.success('删除成功')
        this.loadData()
      } catch (e) {
        this.$Message.success('删除失败')
      }
    },
    async loadData () {
      this.loading.load = true
      await this.loadPfmUnitList()
      let dataList = []
      if (this.mode === 'manage') {
        dataList = await request('/performance/pfm_unit_month', 'get', { pfmMonthId: this.pfmMonthId })
      } else {
        let parm = { pfmMonthId: this.pfmMonthId }
        // if (this.mode === 'operation') {
        //   parm.isYw = 1
        // }
        dataList = await request('/performance/pfm_unit_month/by_auth', 'get', parm)
      }
      const userId = this.$store.getters.userInfo.id
      const pfmUnitType = this.pfmUnitType
      dataList.forEach(item => {
        const manageUserIds = (item.manageUserIds || '').split(',').map(v => Number(v))
        item.authDivide = manageUserIds.includes(userId)
        item.typeText = (pfmUnitType.find(v => v.value === item.type) || { label: '未知' }).label
      })
      this.dataList = dataList
      this.loading.load = false
    },
    async loadMonthList () {
      if (this.pfmMonthId) {
        return this.monthList = []
      }

      this.monthList = await this.$store.dispatch('loadPfmMonthList')
    },
    async itemDivideSubmit (item, type) {
      try {
        this.$Loading.start()
        if (type === 'submit') {
          await request('/performance/pfm_unit_month/push/' + item.id, 'post')
        } else {
          await request('/performance/pfm_unit_month/push_back/' + item.id, 'post')
        }
        this.$Loading.finish()
        this.$Message.success('操作成功')
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '操作失败', content: e.message })
        this.$Loading.error()
      }
    },
    async loadPfmUnitList () {
      if (this.mode === 'manage') {
        this.unitList = await this.$store.dispatch('loadPfmUnitList')
      } else {
        this.unitList = await request('/performance/pfm_unit/by_auth', 'get')
      }
    },
    resize () {
      this.tableHeight = this.$refs.content.offsetHeight
    }
  },
  async mounted () {
    window.addEventListener('resize', this.resize)
    this.resize()

    this.loadMonthList()
    this.loadDefaultConfig()
    this.loadData()
  },
  destroyed () {
    this.unitList = []
    this.dataList = []
    this.monthList = []
    this.defaultConfig = []
    window.removeEventListener('resize', this.resize)
  },
}
</script>
