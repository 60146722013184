<style lang="less" scoped>
  @color-primary: #1cb5e0;
  @color-primary-shadow: rgba(28, 181, 224, .15);
  .current-value, .placeholder {
    cursor: pointer;
    border: 1px solid #dcdee2;
    border-radius: 4px;
    padding: 0 8px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover {
      border-color: @color-primary;
    }
    &:active {
      box-shadow: 0 0 0px 3px @color-primary-shadow;
    }
  }
  .placeholder {
    color: #999;
  }
  .fmico-error-solid {
    margin-left: 8px;
    opacity: 0;
    transition: all .3s;
  }
  .current-value:hover .fmico-error-solid {
    opacity: 1;
  }
</style>

<style>
  .project-type-select .ivu-tooltip, .project-type-select .ivu-tooltip-rel {
    display: block;
  }
</style>

<template>
  <div class="project-type-select">
    <Tooltip v-if="current">
      <div class="current-value" @click="status.drawer = true">
        <span>{{current.name}} - {{current.hisCode}}</span>
        <i v-if="clearable !== false" class="fmico fmico-error-solid" @click.stop.self="clearData"></i>
      </div>
      <div slot="content">
        <div>单项奖励: {{current.isSinglePfm ? '是' : '否'}}</div>
        <div>普通工作量绩效: {{current.isOutUnit ? '不纳入' : '纳入'}}</div>
        <div>默认工作量: {{current.defaultWorkLoad}}</div>
        <div>默认收入: {{current.defaultIncome}}</div>
      </div>
    </Tooltip>
    <template v-else>
      <div class="placeholder" @click="status.drawer = true">{{placeholder}}</div>
    </template>
    <Drawer :transfer="false" :styles="{padding: 0}" height="90" placement="bottom" closable v-model="status.drawer">
      <ProjectPage mode="choose" style="height: 100%;" v-if="status.drawer" @choose="onChoose" />
    </Drawer>
  </div>
</template>

<script>
import { request } from '@/api'
import ProjectPage from '../../views/project/index.vue'

export default {
  components: { ProjectPage },
  props: {
    value: { type: Number, default: null },
    placeholder: { type: String, default: '请选择绩效项目' },
    clearable: { type: Boolean, default: true }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data () {
    return {
      data: this.value,
      current: null,
      status: {
        drawer: false
      }
    }
  },
  watch: {
    value (value) {
      if (value !== this.data) {
        this.data = value
        this.loadData()
      }
    }
  },
  methods: {
    clearData () {
      this.$emit('input', null)
      this.$emit('change', null)
    },
    onChoose (data) {
      this.$emit('input', data.id)
      this.$emit('change', data)
      this.status.drawer = false
    },
    async loadData () {
      if (this.data) {
        const res = await request('/performance/pfm_project', 'get', { id: this.data })
        if (res.length) {
          this.current = res[0]
        } else {
          this.current = null
        }
      } else {
        this.current = null
      }
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>