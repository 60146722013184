<style scoped lang="less">
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EEE;
    padding-bottom: 10px;
    .unit-name {
      font-weight: 800;
    }
  }
  .fields {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .worker-list {}
  .pfm-month-worker {
    &.flex {
      display: flex;
      flex-direction: column;
      .worker-list {
        height: 0;
        flex: 1;
      }
    }
  }
  .title {
    margin-bottom: 10px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
</style>

<template>
  <div class="pfm-month-worker" :class="{ flex }">
    <div class="title">
      <fm-title style="margin: 0 10px;" :title-text="title" :note-text="'共' + dataList.length + '人' + (pfmMonthStatus === 'divide' && pfmUnitMonth && pfmUnitMonth.type !== 'yw' ? ' 平均奖:' + ( pfmMonth ? pfmMonth.avgPfm : '无') : '')" />
      <div style="display: flex;flex-wrap: nowrap;">
        <Button style="margin-right: 10px;" icon="ios-arrow-back" v-if="back" @click="backClick">返回</Button>
        <Button style="margin-right: 10px;" icon="md-refresh-circle" @click="loadData">刷新</Button>
        <template v-if="pfmMonthStatus === 'plan' && mode === 'manage'">
          <Button style="margin-right: 10px;" @click="$refs.import2.click()" :loading="loading.zbInput" type="primary" icon="md-cube">导入</Button>
          <input type="file" ref="import2" accept=".xlsx,.xls" style="display: none;" @input="e => importZbData(e)" />
          <Button style="margin-right: 10px;" @click="zbOutPut" type="primary" icon="md-cube">模版导出</Button>
          <Button style="margin-right: 10px;" icon="md-paper" :loading="loading.init" type="primary" @click="initMonthWorker">初始化全部人员</Button>
          <!-- <Poptip style="margin-right: 10px;" confirm @on-ok="dataSync" title="确定集成吗？">
            <Button :loading="loading.sync" type="warning" icon="md-sync">集成平台设置部分指标</Button>
          </Poptip> -->
          <Poptip placement="left-start" title="新增人员">
            <Button icon="md-add-circle" ref="addbtn" :loading="loading.add" type="primary">新增</Button>
            <div slot="content">
              <Select placeholder="请选择人员" filterable clearable v-model="addFormData.pfmWorkerId">
                <Option v-for="item in workerList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.hisCode }}</Option>
              </Select>
              <Button :loading="loading.add" @click="addSubmit" long type="primary" style="margin-top: 10px;display: block;">确定新增</Button>
              <Alert style="margin-top: 10px;" show-icon v-if="msg.add" type="warning">{{msg.add}}</Alert>
            </div>
          </Poptip>
        </template>
        <template v-if="pfmMonthStatus === 'divide' && mode === 'operation'">
          <Button style="margin-right: 10px;" icon="md-paper" type="primary" @click="outPut">模版导出</Button>
          <Button style="margin-right: 10px;" icon="md-paper" :loading="loading.input" type="primary" @click="$refs.import1.click()">导入</Button>
          <input type="file" ref="import1" accept=".xlsx,.xls" style="display: none;" @input="e => importData(e)" />
          <Poptip placement="left-start" style="margin-right: 10px;" title="删除非本考核单元人员">
            <Button icon="md-trash" ref="addbtn2" :loading="loading.add" type="warning">删除非本考核单元人员</Button>
            <div slot="content">
              <Select placeholder="请选择人员" filterable clearable v-model="divideDelId">
                <Option v-for="item in dataList.filter(v => v.pfmPositionType === 'divide')" :value="item.id" :key="item.id">{{ item.pfmWorkerName }}</Option>
              </Select>
              <Button :loading="loading.add" @click="divideDelSubmit" long type="primary" style="margin-top: 10px;display: block;">确定删除</Button>
              <Alert style="margin-top: 10px;" show-icon v-if="msg.divideDel" type="warning">{{msg.divideDel}}</Alert>
            </div>
          </Poptip>
          <Poptip placement="left-start" style="margin-right: 10px;" title="新增非本考核单元人员">
            <Button icon="md-add-circle" ref="addbtn1" :loading="loading.add" type="primary">新增非本考核单元人员</Button>
            <div slot="content">
              <Select placeholder="请选择人员" filterable clearable v-model="divideAddWorkerId">
                <Option v-for="item in workerList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.hisCode }}</Option>
              </Select>
              <Button :loading="loading.add" @click="divideAddSubmit" long type="primary" style="margin-top: 10px;display: block;">确定新增</Button>
              <Alert style="margin-top: 10px;" show-icon v-if="msg.divideAdd" type="warning">{{msg.divideAdd}}</Alert>
            </div>
          </Poptip>
        </template>
      </div>
    </div>
    <div class="title">
      <fm-form inline style="flex-wrap: nowrap;">
        <fm-form-item style="margin-bottom: 0;">
          <i-input placeholder="请输入搜索内容" search @on-blur="onSearch" @on-search="onSearch" @on-clear="onSearch" clearable v-model="queryForm.value">
            <Select slot="prepend" style="width: 100px" v-model="queryForm.key">
              <Option value="pfmWorkerName">员工</Option>
              <Option value="idNo">身份证</Option>
              <Option value="phone">手机</Option>
            </Select>
          </i-input>
        </fm-form-item>
        <fm-form-item style="margin-bottom: 0;" label="科室">
          <Select @on-change="onSearch" @on-clear="onSearch" style="max-width: 120px;" filterable clearable v-model="queryForm.pfmOrgId">
            <Option v-for="item in orgList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.hisCode }}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item style="margin-bottom: 0;" label="考核单元" v-if="!currentPfmUnitId">
          <Select @on-change="onSearch" @on-clear="onSearch" style="max-width: 120px;" filterable clearable v-model="queryForm.pfmUnitId">
            <Option v-for="item in unitList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.code }}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item style="margin-bottom: 0;" label="职务">
          <Select @on-change="onSearch" @on-clear="onSearch" style="max-width: 120px;" filterable clearable v-model="queryForm.pfmPositionId">
            <Option v-for="item in positionList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.hisCode }}</Option>
          </Select>
        </fm-form-item>
      </fm-form>
    </div>
    <div class="worker-list" ref="content">
      <Table ref="table" :loading="loading.load" :data="pageDataList" :columns="columns" :height="tableHeight" @on-selection-change="onSelectionChange">
        <template slot-scope="{ row }" slot="action">
          <template v-if="mode === 'manage'">
            <Button icon="ios-brush" type="text" size="small" style="margin-right: 5px" @click="itemEdit(row)">修改</Button>
            <Poptip transfer confirm @on-ok="itemDel(row)" title="确定删除吗？">
              <Button icon="md-trash" size="small" type="text">删除</Button>
            </Poptip>
          </template>
        </template>
        <template slot-scope="{ row }" slot="unitValueGw">
          <span v-if="mode !== 'operation'">{{row.unitValueGw}}</span>
          <Poptip v-else transfer title="岗位相关绩效分配">
            <Button icon="ios-create" size="small" type="text">{{row.unitValueGw || '请分配'}}</Button>
            <div slot="content">
              <div style="margin-bottom: 10px;">剩余未分配：{{pfmUnitMonth ? (pfmUnitMonth.unitValueTotal - pfmUnitMonth.divideUnitValueTotal) : '未获取到'}}</div>
              <UnitValue value-key="unitValueGw" @submit="value => onUnitValueSubmit(row, value, 'unitValueGw')" :data="row"/>
            </div>
          </Poptip>
        </template>
        <template slot-scope="{ row }" slot="unitValueGzl">
          <span v-if="mode !== 'operation'">{{row.unitValueGzl}}</span>
          <Poptip v-else transfer title="工作量相关绩效分配">
            <Button icon="ios-create" size="small" type="text">{{row.unitValueGzl || '请分配'}}</Button>
            <div slot="content">
              <div style="margin-bottom: 10px;">剩余未分配：{{pfmUnitMonth ? (pfmUnitMonth.unitValueTotal - pfmUnitMonth.divideUnitValueTotal) : '未获取到'}}</div>
              <UnitValue value-key="unitValueGzl" @submit="value => onUnitValueSubmit(row, value, 'unitValueGzl')" :data="row"/>
            </div>
          </Poptip>
        </template>
      </Table>
    </div>
    <div style="padding: 10px;">
      <Page @on-change="num => page.num = num" :total="showDataList.length" :current="page.num" :page-size="page.size" show-total show-elevator />
    </div>
    <Drawer draggable :mask-closable="false" :mask="!inner" :inner="inner" :transfer="!inner" :title="formData.id ? '修改' : '新增'" width="40" placement="right" closable v-model="status.drawer">
      <fm-form label-alone label-align="left">
        <fm-form-item label="员工">
          <Select readonly disabled placeholder="请选择员工" filterable clearable v-model="formData.pfmWorkerId">
            <Option v-for="item in workerList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.hisCode }}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item label="所属科室">
          <Select placeholder="请选择所属科室" filterable clearable v-model="formData.pfmOrgId">
            <Option v-for="item in orgList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.hisCode }}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item label="所属考核单元">
          <Select placeholder="请选择考核单元" filterable clearable v-model="formData.pfmUnitId">
            <Option v-for="item in unitList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.code }}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item label="所属职务">
          <Select placeholder="请选择所属职务" filterable clearable v-model="formData.pfmPositionId">
            <Option v-for="item in positionList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.hisCode }}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item label="平均绩效系数">
          <fm-input-number v-model="formData.avgPfmRate" placeholder="平均绩效系数" />
        </fm-form-item>
        <fm-form-item v-for="item in defaultConfig.filter(v => v.type === 'input' && v.status === 'enable')" :label="item.valueLabel" :key="item.id">
          <fm-input-number v-model="formData[item.valueKey]" :placeholder="item.valueLabel" />
        </fm-form-item>
        <fm-form-item>
          <div style="display: flex;">
            <Button :loading="loading.update" style="flex: 1;" @click="status.drawer = false" type="default">取消</Button>
            <Button :loading="loading.update" style="margin-left: 10px;flex: 1;" @click="updateSubmit" type="info">确定{{(formData.id ? '修改' : '新增')}}</Button>
          </div>
        </fm-form-item>
      </fm-form>
    </Drawer>
    <Drawer :mask="false" draggable :mask-closable="false" @on-close="$refs.table.selectAll(false)" :inner="inner" :transfer="!inner" :title="'批量操作 - ' + selectionList.length + '条'" width="40" placement="right" closable v-model="batch.show">
      <template v-if="pfmMonthStatus === 'divide' && mode === 'operation'">
        <Divider orientation="left">批量分配</Divider>
        <fm-form>
          <fm-form-item label="剩余未分配">
            <div>{{pfmUnitMonth ? (pfmUnitMonth.unitValueTotal - pfmUnitMonth.divideUnitValueTotal) : '未获取到'}}</div>
          </fm-form-item>
          <fm-form-item label="岗位相关绩效">
            <fm-input-number v-model="batch.unitValueGw" placehodler="请输入岗位相关绩效" />
          </fm-form-item>
          <fm-form-item label="工作量相关绩效">
            <fm-input-number v-model="batch.unitValueGzl" placehodler="请输入工作量相关绩效" />
          </fm-form-item>
          <fm-form-item>
            <Poptip transfer confirm @on-ok="batchAssign" title="确定分配吗？">
              <Button type="primary" :loading="loading.batch">确定批量分配</Button>
            </Poptip>
          </fm-form-item>
        </fm-form>
      </template>
      <template v-if="pfmMonthStatus === 'plan' && mode === 'manage'">
        <Divider orientation="left">批量删除</Divider>
        <Poptip transfer confirm @on-ok="batchDel" title="确定删除吗？">
          <Button :loading="loading.batch" long type="error">批量删除</Button>
        </Poptip>
      </template>
    </Drawer>
    <div style="display: none;" ref="empty"></div>
  </div>
</template>

<script>
import { request } from '@/api'
import UnitValue from './fields/unitValue.vue'

import { xlsxDataMapping } from '../lib'

import {
  fileHepler
} from '@/fmlib'

function getDefault () {
  return {
    pfmMonthId: null,
    pfmWorkerId: null,
    pfmOrgId: null,
    pfmUnitId: null,
    pfmPositionId: null,
    pfmPositiontype: null,
    avgPfmRate: null,
  }
}

function getAddDefault () {
  return {
    pfmWorkerId: null,
    pfmMonthId: null
  }
}

function getDefaultQuery () {
  return {
    key: 'pfmWorkerName',
    value: null,
    pfmOrgId: null,
    pfmUnitId: null,
    pfmPositionId: null
  }
}

export default {
  components: { UnitValue },
  props: {
    mode: { type: String, default: 'view' },
    back: { type: Boolean, default: false },
    flex: { type: Boolean, default: false },
    inner: { type: Boolean, default: false },
    title: { type: String, default: '人员配置' },
    pfmUnitId: { type: Number, default: null },
    pfmMonthId: { type: Number, default: null },
    pfmMonthStatus: { type: String, default: 'plan' },
    pfmMonth: { type: Object }
  },
  data () {
    return {
      dataList: [],
      unitList: [],
      workerList: [],
      selectionList: [],
      defaultConfig: [],
      divideAddWorkerId: null,
      divideDelId: null,
      formData: getDefault(),
      addFormData: getAddDefault(),
      pfmUnitMonth: null,
      query: getDefaultQuery(),
      queryForm: getDefaultQuery(),
      status: {
        drawer: false,
        zbInput: false
      },
      msg: {
        add: null,
        divideAdd: null,
        divideDel: null
      },
      page: {
        size: 10,
        num: 1
      },
      batch: {
        show: false,
        unitValue: null
      },
      loading: {
        load: false,
        update: false,
        add: false,
        init: false,
        batch: false,
        sync: false,
        input: false,
        zbInput: false
      },
      tableHeight: null
    }
  },
  computed: {
    zbOutPutColumn () {
      return [{
        title: 'ID',
        field: 'pfmWorkerId'
      },
      {
        title: '姓名',
        field: 'pfmWorkerName'
      },
      {
        title: '科室',
        field: 'pfmOrgName'
      },
      {
        title: '考核单元',
        field: 'pfmUnitName'
      },
      ...this.defaultConfig.filter(v => v.status === 'enable' && v.type === 'input' && v.valueKey.startsWith('XM')).map(item => {
          return {
            field: item.valueKey, title: item.valueLabel,
            sortable: true, width: 170, ellipsis: true, tooltip: true
          }
        })
      ]
    },
    currentPfmUnitId () {
      if (this.$route.query.pfmUnitId && this.pfmUnitId && this.$route.query.pfmUnitId !== this.pfmUnitId) {
        console.error('同时绑定了路由pfmUnitId和组件pfmUnitId值')
      }
      const pfmUnitId = this.pfmUnitId || this.$route.query.pfmUnitId
      return pfmUnitId ? Number(pfmUnitId) : null
    },
    positionType () {
      return this.$store.getters.pfmPositionType
    },
    orgList () {
      return this.$store.getters.pfmOrgList
    },
    positionList () {
      return this.$store.getters.pfmPositionList
    },
    monthList () {
      return this.$store.getters.pfmMonthList
    },
    columns () {
      const pfmMonthStatus = this.pfmMonthStatus
      const mode = this.mode
      const defaultConfig = this.defaultConfig

      let columns = [
        { key: 'pfmWorkerName', title: '员工', fixed: 'left', width: 120, ellipsis: true, tooltip: true },
        { key: 'idNo', title: '身份证', sortable: true, fixed: 'left', width: 180, ellipsis: true, tooltip: true },
        { key: 'phone', title: '手机', fixed: 'left', width: 125, ellipsis: true, tooltip: true },
        { key: 'pfmUnitName', title: '考核单元', width: 150, ellipsis: true, tooltip: true },
        { key: 'pfmPositionName', title: '职务', width: 150, ellipsis: true, tooltip: true },
        { key: 'pfmOrgName', title: '科室', width: 150, ellipsis: true, tooltip: true },
        { key: 'avgPfmRate', title: '平均绩效系数', sortable: true, width: 140, ellipsis: true, tooltip: true },
        ...(pfmMonthStatus === 'plan' ? [] : [
          { key: 'unitValueGw', title: '岗位相关绩效', sortable: true, width: 150, ellipsis: true, tooltip: true, slot: 'unitValueGw', fixed: mode === 'operation' ? 'right' : undefined },
          { key: 'unitValueGzl', title: '工作量相关绩效', sortable: true, width: 180, ellipsis: true, tooltip: true, slot: 'unitValueGzl', fixed: mode === 'operation' ? 'right' : undefined }
        ]),
        ...defaultConfig.filter(v => v.status === 'enable').map(item => {
          return {
            key: item.valueKey, title: item.valueLabel,
            sortable: true, width: 170, ellipsis: true, tooltip: true
          }
        })
      ]

      if (pfmMonthStatus === 'plan' && mode === 'manage') {
        columns = [
          { type: 'selection', width: 60, align: 'center', fixed: 'left' },
          ...columns,
          { key: 'action', title: '操作', slot: 'action', width: 190, fixed: 'right' }
        ]
      } else if (pfmMonthStatus === 'divide' && mode === 'operation') {
        columns = [
          { type: 'selection', width: 60, align: 'center', fixed: 'left' },
          ...columns
        ]
      }

      return columns
    },
    showDataList () {
      const key = this.query.key
      const value = this.query.value
      const pfmOrgId = this.query.pfmOrgId
      const pfmUnitId = this.currentPfmUnitId ? this.currentPfmUnitId : this.query.pfmUnitId
      const pfmPositionId = this.query.pfmPositionId
      return this.dataList.filter(item => {
        if (key && value && item[key] && String(item[key]).indexOf(value) === -1) {
          return false
        }
        if (pfmOrgId && item.pfmOrgId !== pfmOrgId) {return false;}
        if (pfmUnitId && item.pfmUnitId !== pfmUnitId) {return false;}
        if (pfmPositionId && item.pfmPositionId !== pfmPositionId) {return false;}
        return true
      })
    },
    pageDataList () {
      const start = (this.page.num - 1) * this.page.size
      const end = start + this.page.size
      return this.showDataList.slice(start, end)
    }
  },
  watch: {
    mode () {
      this.page.num = 1
      this.loadUnitList()
      this.loadWorkerList()
      this.loadData()
    },
    currentPfmUnitId () {
      this.page.num = 1
      this.loadPfmUnitMonth()
    },
    pfmMonthId () {
      this.page.num = 1
      this.loadDefaultConfig()
      this.loadData()
    }
  },
  methods: {
    async dataSync () {
      this.loading.sync = true
      try {
        await request('/performance/pfm_worker_month/sync', 'post', { pfmMonthId: this.pfmMonthId })
        this.loadData()
      } catch (e) {
        console.error(e)
      }
      this.loading.sync = false
    },
    outPut () {
      let columnList = [{
        title: '姓名',
        field: 'pfmWorkerName'
      },
      {
        title: '岗位相关绩效',
        field: 'unitValueGw'
      },
      {
        title: '工作量相关绩效',
        field: 'unitValueGzl'
      },
      {
        title: '合计',
        field: 'unitValue'
      }]
      fileHepler.outPutTableData(this.dataList, columnList, '绩效分配模版.xls')
    },
    zbOutPut () {
      let dataNote = {}
      this.defaultConfig.filter(v => v.status === 'enable' && v.type === 'input' && v.valueKey.startsWith('XM')).forEach(v => {
        dataNote[v.valueKey] = ['XM7', 'XM8', 'XM9', 'XM10', 'XM11', 'XM19', 'XM20', 'XM21', 'XM22', 'XM23', 'XM28'].includes(v.valueKey) ? '执行总金额' : '完成数量'
      })
      let data = [dataNote].concat(this.dataList.filter(v => v.pfmUnitMonthType === 'yw'))
      fileHepler.outPutTableData(data, this.zbOutPutColumn, '人员月度指标导入模版.xls')
    },
    async importZbData ({ target }) {
      if (!target.files.length || this.loading.zbInput) {
        return
      }

      try {
        this.loading.zbInput = true
        this.$Spin.show({
          render: (h) => {
            return h('div', [
              h('Icon', {
                'class': 'demo-spin-icon-load',
                props: {
                  type: 'ios-loading',
                  size: 18
                }
              }),
              h('div', '上传中...')
            ])
          }
        })
        let datas = await xlsxDataMapping(target.files[0], this.zbOutPutColumn.map(v => {
          return {
            key: v.field,
            title: v.title
          }
        }))

        datas = datas.filter(v => v.pfmWorkerId)

        await request('/performance/pfm_worker_month/zb_batch', 'post', {
          pfmMonthId: this.pfmMonthId,
          dataList: datas
        })
        this.$Spin.hide();
        this.$Message.success('导入指标完成')
        this.loading.zbInput = false
        this.loadData()
      } catch (e) {
        this.loading.zbInput = false
        this.$Spin.hide();
        this.$Modal.error({ title: '导入失败', content: e.message })
      }
      target.value = null
    },
    async importData ({ target }) {
      if (!target.files.length || this.loading.input) {
        return
      }
      const keyMap = [
        { title: '姓名', key: 'pfmWorkerName' },
        { title: '岗位相关绩效', key: 'unitValueGw' },
        { title: '工作量相关绩效', key: 'unitValueGzl' },
        { title: '合计', key: 'unitValue' },
      ]

      try {
        this.loading.input = true
        this.$Spin.show({
          render: (h) => {
            return h('div', [
              h('Icon', {
                'class': 'demo-spin-icon-load',
                props: {
                  type: 'ios-loading',
                  size: 18
                }
              }),
              h('div', '上传中...')
            ])
          }
        })
        const datas = await xlsxDataMapping(target.files[0], keyMap)

        await request('/performance/pfm_worker_month/excel_batch', 'post', {
          pfmMonthId: this.pfmMonthId,
          pfmUnitId: this.currentPfmUnitId,
          dataList: datas
        })
        this.$Spin.hide();
        this.$Message.success('导入分配完成')
        this.loading.input = false
        this.loadData()
      } catch (e) {
        this.loading.input = false
        this.$Spin.hide();
        this.$Modal.error({ title: '导入失败', content: e.message })
      }
      target.value = null
    },
    onSearch () {
      this.page.num = 1
      this.query = JSON.parse(JSON.stringify(this.queryForm))
    },
    async loadDefaultConfig () {
      this.defaultConfig = await request('/performance/pfm_month/worker_config/' + this.pfmMonthId)
    },
    onUnitValueSubmit (row, value, key) {
      if (key === 'unitValueGw') {
        row.unitValueGw = value
      } else {
        row.unitValueGzl = value
      }
      this.$refs.empty.click()
      this.loadPfmUnitMonth()
    },
    onSelectionChange (e) {
      this.selectionList = e
      this.batch.pfmMonthId = null
      this.batch.pfmUnitId = null
      this.batch.unitValue = null
      this.batch.show = this.selectionList.length > 0
    },
    itemEdit (item) {
      this.formData = item
      this.status.drawer = true
    },
    backClick () {
      this.$emit('back')
    },
    async initMonthWorker () {
      try {
        this.loading.init = true
        await request('/performance/pfm_worker_month/init', 'post', { pfmMonthId: this.pfmMonthId, initDefault: 1 })
        this.loading.init = false
        this.$Message.success('初始化成功')
        this.page.num = 1
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '初始化失败', content: e.message })
        console.error(e)
        this.loading.init = false
      }
    },
    async addSubmit () {
      if (!this.addFormData.pfmWorkerId) {
        return this.msg.add = '请选择人员'
      }
      this.msg.add = null
      this.loading.add = true
      try {
        this.addFormData.pfmMonthId = this.pfmMonthId
        await request('/performance/pfm_worker_month', 'post', this.addFormData)
        this.loading.add = false
        this.$Message.success('操作成功')
        this.loadData()
        this.page.num = 1
        this.addFormData = getAddDefault()
        this.$ref.addbtn.$el.click()
      } catch (e) {
        this.loading.add = false
      }
    },
    async divideAddSubmit () {
      if (!this.divideAddWorkerId) {
        return this.msg.divideAdd = '请选择人员'
      }
      this.msg.divideAdd = null
      this.loading.add = true
      try {
        let parm = {
          pfmWorkerId: this.divideAddWorkerId,
          pfmMonthId: this.pfmMonthId,
          pfmUnitId: this.currentPfmUnitId
        }
        await request('/performance/pfm_worker_month/divide', 'post', parm)
        this.loading.add = false
        this.$Message.success('操作成功')
        this.loadData()
        this.page.num = 1
        this.divideAddWorkerId = null
        this.$ref.addbtn1.$el.click()
      } catch (e) {
        this.loading.add = false
      }
    },
    async divideDelSubmit () {
      if (!this.divideDelId) {
        return this.msg.divideDel = '请选择人员'
      }
      this.msg.divideDel = null
      this.loading.add = true
      try {
        await request('/performance/pfm_worker_month/divide/' + this.divideDelId, 'delete')
        this.loading.add = false
        this.$Message.success('操作成功')
        this.loadData()
        this.page.num = 1
        this.divideDelId = null
        this.$ref.addbtn2.$el.click()
      } catch (e) {
        this.loading.add = false
      }
    },
    async updateSubmit () {
      this.loading.update = true
      try {
        this.formData.pfmMonthId = this.pfmMonthId
        await request('/performance/pfm_worker_month/' + this.formData.id, 'put', this.formData)
        this.status.drawer = false
        this.loading.update = false
        this.$Message.success('操作成功')
        this.loadData()
      } catch (e) {
        this.loading.update = false
      }
    },
    async batchAssign () {
      try {
        this.loading.batch = true
        await request('/performance/pfm_worker_month/batch', 'post', {
          pfmMonthId: this.pfmMonthId,
          pfmUnitId: this.currentPfmUnitId,
          dataList: this.selectionList.map(v => {
            return { pfmWorkerId: v.pfmWorkerId, unitValueGw: this.batch.unitValueGw, unitValueGzl: this.batch.unitValueGzl }
          })
        })
        this.loadData()
        this.$Message.success('操作成功')
        this.loading.batch = false
        this.batch.show = false
      } catch (e) {
        this.$Message.success('操作失败')
        this.loading.batch = false
      }
    },
    async batchDel () {
      try {
        this.loading.batch = true
        const promise = this.selectionList.map(v => {
          return request('/performance/pfm_worker_month/' + v.id, 'delete')
        })
        await Promise.all(promise)
        this.$Message.success('已删除')
        this.loading.batch = false
        this.batch.show = false
        this.page.num = 1
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '删除失败', content: e.message })
        this.loading.batch = false
      }
    },
    async itemDel (item) {
      try {
        await request('/performance/pfm_worker_month/' + item.id, 'delete')
        this.$Message.success('删除成功')
        this.loadData()
      } catch (e) {
        this.$Message.success('删除失败')
      }
    },
    async loadData () {
      this.loading.load = true
      const params = { pfmMonthId: this.pfmMonthId }
      if (this.currentPfmUnitId) {
        params.pfmUnitId = this.currentPfmUnitId
      }

      // if (this.mode === 'operation') {
      //   params.isYw = 1
      // }

      let dataList
      if (this.mode === 'manage') {
        dataList = await request('/performance/pfm_worker_month', 'get', params)
      } else {
        dataList = await request('/performance/pfm_worker_month/by_auth', 'get', params)
      }

      this.loadPfmUnitMonth()

      this.dataList = dataList
      this.loading.load = false
    },
    resize () {
      this.tableHeight = this.$refs.content.offsetHeight
      this.page.size = Math.ceil((this.tableHeight - 40) / 48)
    },
    async loadWorkerList () {
      this.workerList = await request('/performance/pfm_worker', 'get')
      // if (this.mode === 'manage') {
      //   this.workerList = await request('/performance/pfm_worker', 'get')
      // } else {
      //   this.workerList = await request('/performance/pfm_worker/by_auth', 'get')
      // }
    },
    async loadUnitList () {
      if (this.mode === 'manage') {
        this.unitList = await this.$store.dispatch('loadPfmUnitList')
      } else {
        const unitList = await request('/performance/pfm_unit/by_auth')
        this.unitList = unitList
        // if (this.mode === 'operation') {
        //   this.unitList = unitList.filter(v => v.type === 'yw')
        // } else {
        //   this.unitList = unitList
        // }
      }
    },
    async loadPfmUnitMonth () {
      if (!this.currentPfmUnitId) {
        return
      }
      let dataList = []
      if (this.$authFunsProxyNoRoute['performance.calculate.index-manage']) {
        dataList = await request('/performance/pfm_unit_month', 'get', { pfmMonthId: this.pfmMonthId})
      } else {
        dataList = await request('/performance/pfm_unit_month/by_auth', 'get', { pfmMonthId: this.pfmMonthId})
      }
      this.pfmUnitMonth = dataList.find(v => v.pfmUnitId === this.currentPfmUnitId)
      if (!this.pfmUnitMonth) {
        this.$Modal.error({
          title: '系统提示',
          content: '未获取到相关考核单元',
          onOk: () => this.$router.back()
        })
      }
    }
  },
  async mounted () {
    window.addEventListener('resize', this.resize)
    this.resize()

    this.$store.dispatch('loadPfmOrgList')
    this.$store.dispatch('loadPfmMonthList')
    this.$store.dispatch('loadPfmPositionList')

    this.loadUnitList()
    this.loadWorkerList()

    this.loadDefaultConfig()
    this.loadData()
  },
  destroyed () {
    this.dataList = []
    this.unitList = []
    this.workerList = []
    this.defaultConfig = []
    window.removeEventListener('resize', this.resize)
  },
}
</script>
