<style scoped lang="less">
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EEE;
    padding-bottom: 10px;
    .unit-name {
      font-weight: 800;
    }
  }
  .fields {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .project-list {
    margin-top: 10px;
  }
  .pfm-month-project {
    &.flex {
      display: flex;
      flex-direction: column;
      .project-list {
        height: 0;
        flex: 1;
      }
    }
  }
  .import-box {
    border: 1px dashed #dcdee2;
    border-radius: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    height: 150px;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    span {
      font-weight: bold;
      font-size: 15px;
    }
    &, span {
      transition: all .3s;
    }
    &:hover {
      border-color: #2d8cf0;
      color: #2d8cf0;
    }
    &:active {
      background-color: #EEE;
    }
  }
</style>

<style>
.demo-spin-icon-load{
  animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
  from { transform: rotate(0deg);}
  50%  { transform: rotate(180deg);}
  to   { transform: rotate(360deg);}
}
</style>

<template>
  <div class="pfm-month-project" :class="{ flex }">
    <Row justify="space-between" align="middle">
      <i-col>
        <fm-title style="margin: 0 5px;" :title-text="title" :note-text="'共' + page.total + '项'"></fm-title>
      </i-col>
      <i-col>
        <Row :gutter="10" style="margin-right: 5px;" type="flex" :wrap="false">
          <i-col v-if="back"><Button icon="ios-arrow-back" @click="backClick">返回</Button></i-col>
          <i-col v-if="pfmMonthStatus === 'plan' && mode === 'manage' && false">
            <Button v-if="pfmMonthId" icon="md-refresh-circle" @click="initMonth">初始化</Button>
            <Poptip v-else trigger="click" transfer title="请选择要初始化的月份">
              <Button :loading="loading.init" icon="md-refresh-circle">初始化</Button>
              <div slot="content">
                <Select placeholder="请选择月度绩效" filterable clearable v-model="initPfmMonthId">
                  <Option v-for="item in monthList" :key="item.id" :value="item.id">{{item.month}} - {{item.statusText}}</Option>
                </Select>
                <Button type="primary" :loading="loading.init" @click="initMonth" style="display: block;width: 100%;margin-top: 10px;">确定</Button>
              </div>
            </Poptip>
          </i-col>
          <i-col><Button icon="md-refresh-circle" @click="loadData">刷新</Button></i-col>
          <i-col v-if="pfmMonthStatus === 'plan' && mode === 'manage'">
            <Poptip confirm @on-ok="dataSync" title="确定his项目同步吗？">
              <Button :loading="loading.sync" type="info" icon="md-sync">his项目同步</Button>
            </Poptip>
            <Button style="margin-left: 10px;" @click="sourcePanel.his = true" :loading="loading.sync" type="primary" icon="md-cube">his项目导入和清空</Button>
            <Button style="margin-left: 10px;" @click="sourcePanel.common = true" :loading="loading.sync" type="primary" icon="md-cube">公卫项目导入和清空</Button>
          </i-col>
          <i-col v-if="pfmMonthStatus === 'plan' && mode === 'manage'"><Button icon="md-add-circle" @click="addClick" type="primary">新增</Button></i-col>
        </Row>
      </i-col>
    </Row>
    <Row justify="space-between" align="middle" :gutter="10" type="flex" :wrap="false" style="margin: 0 10px;">
      <i-col>
        <Row align="middle" :gutter="10" type="flex" :wrap="false">
          <i-col>
            <i-input placeholder="请输入搜索内容" search @on-blur="onSearch()" @on-search="onSearch()" @on-clear="onSearch()" clearable v-model="query.value">
              <Select @on-change="onSearch()" slot="prepend" style="width: 100px" v-model="query.key">
                <Option value="name">项目名称</Option>
                <Option value="patientName">患者姓名</Option>
                <Option value="zyCode">门诊号</Option>
                <Option value="hisCode">his编码</Option>
              </Select>
            </i-input>
          </i-col>
          <!-- <i-col>单项奖励: </i-col>
          <i-col>
            <Dropdown trigger="click" @on-click="name => onSearch({isSinglePfm: name})">
              <Button type="primary" v-if="query.isSinglePfm === 'yes'">是<Icon type="ios-arrow-down"></Icon></Button>
              <Button type="warning" v-else-if="query.isSinglePfm === 'no'">否<Icon type="ios-arrow-down"></Icon></Button>
              <Button type="default" v-else>全部<Icon type="ios-arrow-down"></Icon></Button>
              <DropdownMenu slot="list">
                <DropdownItem :selected="query.isSinglePfm === 'all'" name="all">全部</DropdownItem>
                <DropdownItem :selected="query.isSinglePfm === 'yes'" name="yes">是</DropdownItem>
                <DropdownItem :selected="query.isSinglePfm === 'no'" name="no">否</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </i-col> -->
          <i-col>普通工作量绩效: </i-col>
          <i-col>
            <Dropdown trigger="click" @on-click="name => onSearch({isOutUnit: name})">
              <Button type="primary" v-if="query.isOutUnit === 'yes'">不纳入<Icon type="ios-arrow-down"></Icon></Button>
              <Button type="warning" v-else-if="query.isOutUnit === 'no'">纳入<Icon type="ios-arrow-down"></Icon></Button>
              <Button type="default" v-else>全部<Icon type="ios-arrow-down"></Icon></Button>
              <DropdownMenu slot="list">
                <DropdownItem :selected="query.isOutUnit === 'all'" name="all">全部</DropdownItem>
                <DropdownItem :selected="query.isOutUnit === 'yes'" name="yes">不纳入</DropdownItem>
                <DropdownItem :selected="query.isOutUnit === 'no'" name="no">纳入</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </i-col>
          <i-col>
            <Poptip trigger="click" transfer title="筛选设置">
              <Button type="default" icon="md-apps">更多筛选</Button>
              <div slot="content" style="display: flex;flex-direction: column;">
                <fm-form>
                  <fm-form-item label="项目类型">
                    <Select @on-change="onSearch()" @on-clear="onSearch()" placeholder="请选择项目类型" filterable clearable v-model="query.pfmProjectTypeId">
                      <Option v-for="item in projectTypeList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.hisCode }}</Option>
                    </Select>
                  </fm-form-item>
                  <fm-form-item label="项目大类">
                    <Select @on-change="onSearch()" @on-clear="onSearch()" placeholder="请选择项目大类" filterable clearable v-model="query.pfmProjectTypePId">
                      <Option v-for="item in projectTypeList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.hisCode }}</Option>
                    </Select>
                  </fm-form-item>
                  <fm-form-item label="项目大类">
                    <Select @on-change="onSearch()" @on-clear="onSearch()" placeholder="请选择项目大类" filterable clearable v-model="query.type">
                      <Option v-for="item in projectBillType" :value="item.value" :key="item.value">{{item.label}}</Option>
                    </Select>
                  </fm-form-item>
                  <fm-form-item label="开单科室">
                    <Select @on-change="onSearch()" @on-clear="onSearch()" placeholder="请选择开单科室" filterable clearable v-model="query.billingPfmOrgId">
                      <Option v-for="item in orgList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.hisCode }}</Option>
                    </Select>
                  </fm-form-item>
                  <fm-form-item label="执行科室">
                    <Select @on-change="onSearch()" @on-clear="onSearch()" placeholder="请选择执行科室" filterable clearable v-model="query.executePfmOrgId">
                      <Option v-for="item in orgList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.hisCode }}</Option>
                    </Select>
                  </fm-form-item>
                  <fm-form-item label="开单考核单元">
                    <Select @on-change="onSearch()" @on-clear="onSearch()" placeholder="请选择开单考核单元" filterable clearable v-model="query.billingPfmUnitId">
                      <Option v-for="item in unitList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.code }}</Option>
                    </Select>
                  </fm-form-item>
                  <fm-form-item label="执行考核单元">
                    <Select @on-change="onSearch()" @on-clear="onSearch()" placeholder="请选择执行考核单元" filterable clearable v-model="query.executePfmUnitId">
                      <Option v-for="item in unitList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.code }}</Option>
                    </Select>
                  </fm-form-item>
                </fm-form>
              </div>
            </Poptip>
          </i-col>
        </Row>
      </i-col>
    </Row>
    <div class="project-list" ref="content">
      <Table ref="table" :loading="loading.load" @on-sort-change="onSortChange" :data="dataList" :columns="columns" :height="tableHeight" @on-selection-change="onSelectionChange">
        <template slot-scope="{ row }" slot="month">
          <FieldMonth :monthList="monthList" :row="row" />
        </template>
        <template slot-scope="{ row }" slot="isOutUnit">
          <Tooltip max-width="250" :disabled="!row.isOutUnit" transfer :content="row.outUnitRemark || '暂无说明'">
            <Tag class="noborder" :color="row.isOutUnit ? 'orange' : 'blue'">{{row.isOutUnit ? '不纳入' : '纳入'}}</Tag>
          </Tooltip>
        </template>
        <template slot-scope="{ row }" slot="isSinglePfm">
          <Tag class="noborder" v-if="!row.isSinglePfm" color="orange">否</Tag>
          <template v-else>
            <Poptip trigger="hover" transfer title="单项奖励配置">
              <Tag class="noborder" color="blue">是</Tag>
              <div slot="content" style="max-height: 200px;overflow: auto;">
                <div v-for="item in row.singlePfmConfig" :key="item.id">项目: {{item.pfmProjectName || '-'}} 奖励: {{item.pfmValue}} 类型: {{item.type}}</div>
              </div>
            </Poptip>
          </template>
        </template>
        <template slot-scope="{ row }" slot="action">
          <!--<Tooltip placement="left" transfer :content="row.detailList.length + '条明细'">
            <Button icon="ios-people" type="text" size="small" style="margin-right: 5px" @click="itemDetails(row)">明细</Button>
          </Tooltip> -->
          <Button icon="ios-brush" type="text" size="small" style="margin-right: 5px" @click="itemEdit(row)">修改</Button>
          <Poptip transfer confirm @on-ok="itemDel(row)" title="确定删除吗？">
            <Button icon="md-trash" size="small" type="text">删除</Button>
          </Poptip>
        </template>
      </Table>
    </div>
    <div style="padding: 10px;">
      <Page @on-change="onPageChange" :total="page.total" :current="page.num" :page-size="page.size" show-total show-elevator />
    </div>
    <Drawer :mask="!inner" :inner="inner" :transfer="!inner" :title="formData.id ? '修改' : '新增'" width="40" placement="right" closable v-model="status.drawer">
      <fm-form v-if="status.drawer" label-alone label-align="left">
        <fm-form-item label="his编码">
          <fm-input-new v-model="formData.hisCode" placeholder="请输入his编码" clearable />
        </fm-form-item>
        <fm-form-item label="项目">
          <ProjectSelect v-model="formData.pfmProjectId"/>
        </fm-form-item>
        <fm-form-item label="项目类型">
          <Select placeholder="请选择项目类型" filterable clearable v-model="formData.pfmProjectTypeId">
            <Option v-for="item in projectTypeList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.hisCode }}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item label="项目大类">
          <Select placeholder="请选择项目大类" filterable clearable v-model="formData.pfmProjectTypePId">
            <Option v-for="item in projectTypeList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.hisCode }}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item label="项目大类">
          <Select placeholder="请选择项目大类" filterable clearable v-model="formData.type">
            <Option v-for="item in projectBillType" :value="item.value" :key="item.value">{{item.label}}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item label="时间">
          <DatePicker v-model="formData.basisTime" type="date" placeholder="请选择时间"></DatePicker>
        </fm-form-item>
        <fm-form-item v-if="!pfmMonthId && !formData.id" label="月度绩效">
          <Select placeholder="请选择月度绩效" filterable clearable v-model="formData.pfmMonthId">
            <Option v-for="item in monthList" :key="item.id" :value="item.id">{{item.month}} - {{item.statusText}}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item label="开单科室">
          <Select placeholder="请选择开单科室" filterable clearable v-model="formData.billingPfmOrgId">
            <Option v-for="item in orgList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.hisCode }}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item label="执行科室">
          <Select placeholder="请选择执行科室" filterable clearable v-model="formData.executePfmOrgId">
            <Option v-for="item in orgList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.hisCode }}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item label="开单考核单元">
          <Select placeholder="请选择开单考核单元" filterable clearable v-model="formData.billingPfmUnitId">
            <Option v-for="item in unitList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.code }}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item label="执行考核单元">
          <Select placeholder="请选择执行考核单元" filterable clearable v-model="formData.executePfmUnitId">
            <Option v-for="item in unitList" :value="item.id" :key="item.id">{{ item.name }} - {{ item.code }}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item label="工作量">
          <fm-input-number v-model="formData.workLoad" />
        </fm-form-item>
        <fm-form-item label="收入">
          <fm-input-number v-model="formData.income" />
        </fm-form-item>
        <fm-form-item label="大循环绩效">
          <fm-switch size="small" v-model="formData.isOutUnit">
            <span slot="on">不纳入</span>
            <span slot="off">纳入</span>
          </fm-switch>
        </fm-form-item>
        <fm-form-item v-if="formData.isOutUnit" label="不纳入说明">
          <fm-input-new placeholder="不纳入大循环绩效说明" v-model="formData.outUnitRemark" />
        </fm-form-item>
        <!--
        <fm-form-item label="单项奖励">
          <fm-switch size="small" v-model="formData.isSinglePfm">
            <span slot="on">是</span>
            <span slot="off">否</span>
          </fm-switch>
        </fm-form-item> -->
        <fm-form-item label="数量">
          <fm-input-number v-model="formData.num" />
        </fm-form-item>
        <fm-form-item label="单价">
          <fm-input-number v-model="formData.price" />
        </fm-form-item>
        <fm-form-item label="单工作量">
          <fm-input-number v-model="formData.oneWorkLoad" />
        </fm-form-item>
        <fm-form-item label="患者姓名">
          <fm-input-new v-model="formData.patientName" placeholder="请输入患者姓名" clearable />
        </fm-form-item>
        <fm-form-item label="门诊号">
          <fm-input-new v-model="formData.zyCode" placeholder="请输入门诊号" clearable />
        </fm-form-item>
        <fm-form-item>
          <div style="display: flex;">
            <Button :loading="loading.submit" style="flex: 1;" @click="status.drawer = false" type="default">取消</Button>
            <Button :loading="loading.submit" style="margin-left: 10px;flex: 1;" @click="submit" type="info">确定{{(formData.id ? '修改' : '新增')}}</Button>
          </div>
        </fm-form-item>
      </fm-form>
    </Drawer>
    <Drawer draggable :mask-closable="false" :mask="!inner" :inner="inner" :transfer="!inner" :title="formData.name + ' - 业务单明细'" width="40" placement="right" closable v-model="status.details">
      <DetailsList :mode="mode" v-if="status.details && formData && formData.id" :singlePfmConfig="formData.singlePfmConfig" :pfmProjectBillId="formData.id" :pfmMonthStatus="pfmMonthStatus" />
    </Drawer>
    <Drawer :mask="false" draggable :mask-closable="false" @on-close="$refs.table.selectAll(false)" :inner="inner" :transfer="!inner" :title="'业务单批量操作 - ' + selectionList.length + '条'" width="40" placement="right" closable v-model="batch.show">
      <Divider orientation="left">批量分配</Divider>
      <Alert show-icon closable>已分配的业务单，不可重复分配，仅可分配到准备中的月度绩效</Alert>
      <fm-form>
        <fm-form-item>
          <Select placeholder="请选择月度绩效" filterable clearable v-model="batch.assginPfmMonthId">
            <Option v-for="item in monthList.filter(v => v.status === 'plan')" :key="item.id" :value="item.id">{{item.month}} - {{item.statusText}}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item>
          <Poptip transfer confirm @on-ok="batchAssignMonth" title="确定分配吗？">
            <Button type="primary" :loading="loading.batch">确定批量分配</Button>
          </Poptip>
        </fm-form-item>
        <fm-form-item v-if="msg.batchAssign">
          <Alert v-if="msg.batchAssign" type="warning" show-icon>{{msg.batchAssign}}</Alert>
        </fm-form-item>
      </fm-form>
      <Divider orientation="left">批量移除月度绩效</Divider>
      <Poptip transfer confirm @on-ok="batchRemoveMonth" title="确定移除吗？">
        <Button :loading="loading.batch" long type="error">移除</Button>
      </Poptip>
      <Divider orientation="left">批量删除</Divider>
      <Poptip transfer confirm @on-ok="batchDel" title="确定删除吗？">
        <Button :loading="loading.batch" long type="error">批量删除</Button>
      </Poptip>
    </Drawer>
    <Drawer title="公卫项目导入和清空" width="40" placement="right" closable v-model="sourcePanel.common">
      <input type="file" ref="import1" accept=".xlsx,.xls" style="display: none;" @input="e => importData(e, 1)" />
      <div class="import-box" @click="$refs.import1.click()">
        <Icon type="md-cloud-upload" size="40" />
        <span>点击导入公卫项目</span>
      </div>
      <Poptip style="margin-top: 10px;" class="block" transfer confirm @on-ok="truncate(1)" title="确定清空吗？">
        <Button :loading="loading.sync" type="error" icon="md-trash" long>清空公卫项目</Button>
      </Poptip>
    </Drawer>
    <Drawer title="his项目导入和清空" width="40" placement="right" closable v-model="sourcePanel.his">
      <input type="file" ref="import2" accept=".xlsx,.xls" style="display: none;" @input="e => importData(e, 2)" />
      <div class="import-box" @click="$refs.import2.click()">
        <Icon type="md-cloud-upload" size="40" />
        <span>点击导入his项目</span>
      </div>
      <Poptip style="margin-top: 10px;" class="block" transfer confirm @on-ok="truncate(2)" title="确定清空吗？">
        <Button :loading="loading.sync" type="error" icon="md-trash" long>清空his项目</Button>
      </Poptip>
    </Drawer>
  </div>
</template>

<script>
import { request } from '@/api'

import { getPfmMonthStatusText } from '../../../store/index'

import DetailsList from './details.vue'
import FieldMonth from './fields/month.vue'
import ProjectSelect from '../../../components/select/Project.vue'
import { xlsxDataMapping } from '../lib'

function getDefault () {
  return {
    id: null,
    hisCode: null,
    pfmProjectId: null,
    pfmProjectTypeId: null,
    pfmProjectTypePId: null,
    type: null,
    basisTime: new Date(),
    pfmMonthId: null,
    billingPfmOrgId: null,
    executePfmOrgId: null,
    billingPfmUnitId: null,
    executePfmUnitId: null,
    workLoad: null,
    income: null,
    isOutUnit: false,
    outUnitRemark: null,
    isSinglePfm: false,
    singlePfmConfig: null,
    initData: false,
    num: null,
    price: null,
    oneWorkLoad: null,
    patientName: null,
    zyCode: null
  }
}

function getDefaultQuery () {
  return {
    key: 'name',
    value: null,
    isOutUnit: 'all',
    isSinglePfm: 'all',
    type: null,
    pfmProjectTypeId: null,
    pfmProjectTypePId: null,
    billingPfmOrgId: null,
    executePfmOrgId: null,
    billingPfmUnitId: null,
    executePfmUnitId: null,
    sort: {
      key: 'basisTime',
      type: 'desc'
    }
  }
}

export default {
  components: { DetailsList, FieldMonth, ProjectSelect },
  props: {
    mode: { type: String, default: 'view' },
    back: { type: Boolean, default: false },
    flex: { type: Boolean, default: false },
    inner: { type: Boolean, default: false },
    title: { type: String, default: '开展项目' },
    pfmMonthId: { type: Number, default: null },
    pfmMonthStatus: { type: String, default: 'plan' }
  },
  data () {
    return {
      dataList: [],
      unitList: [],
      selectionList: [],
      formData: getDefault(),
      query: getDefaultQuery(),
      status: {
        drawer: false,
        details: false
      },
      loading: {
        load: false,
        submit: false,
        init: false,
        batch: false,
        sync: false
      },
      msg: {
        batchAssign: null
      },
      page: {
        num: 1,
        size: 10,
        total: 0
      },
      tableHeight: null,
      initPfmMonthId: null,
      batch: {
        show: false,
        assginPfmMonthId: null
      },
      sourcePanel: {
        common: false,
        his: false
      }
    }
  },
  computed: {
    projectTypeList () {
      return this.$store.getters.pfmProjectTypeList
    },
    orgList () {
      return this.$store.getters.pfmOrgList
    },
    monthList () {
      return this.$store.getters.pfmMonthList
    },
    projectBillType () {
      return this.$store.getters.pfmProjectBillType
    },
    columns () {const columns = [
        { key: 'name', title: '项目名称', fixed: 'left', width: 180, ellipsis: true, tooltip: true },
        { key: 'pfmMonth', title: '绩效月份', fixed: 'left', align: 'center', width: 110, slot: 'month' },
        { key: 'typeText', title: '业务单类型', width: 180, ellipsis: true, tooltip: true },
        { key: 'pfmProjectTypeName', title: '项目类型', width: 180, ellipsis: true, tooltip: true },
        { key: 'pfmProjectTypePName', title: '项目大类', width: 180, ellipsis: true, tooltip: true },
        { key: 'billingPfmOrgName', title: '开单科室', width: 200 },
        { key: 'executePfmOrgName', title: '执行科室', width: 200 },
        { key: 'billingPfmUnitName', title: '开单考核单元', width: 200 },
        { key: 'executePfmUnitName', title: '执行考核单元', width: 200 },
        { key: 'workLoad', title: '工作量', width: 100, sortable: 'custom', ellipsis: true, tooltip: true },
        { key: 'income', title: '收入', width: 100, sortable: 'custom', ellipsis: true, tooltip: true },
        { key: 'isOutUnit', title: '大循环绩效', width: 120, slot: 'isOutUnit' },
        // { key: 'isSinglePfm', title: '单项奖励', width: 100, slot: 'isSinglePfm' },
        { key: 'num', title: '数量', sortable: 'custom', width: 100 },
        { key: 'price', title: '单价', sortable: 'custom', width: 100 },
        { key: 'oneWorkLoad', title: '单工作量', sortable: 'custom', width: 110 },
        { key: 'patientName', title: '患者姓名', width: 110 },
        { key: 'zyCode', title: '门诊号', width: 100 },
        { key: 'basisTime', title: '时间', width: 180, sortable: 'custom', ellipsis: true, tooltip: true },
        { key: 'hisCode', title: 'his编码', width: 180, ellipsis: true, tooltip: true }
      ]

      if (this.pfmMonthStatus === 'plan' && this.mode === 'manage') {
        return [
          { type: 'selection', width: 60, align: 'center', fixed: 'left' },
          ...columns,
          { key: 'action', title: '操作', slot: 'action', width: 200, fixed: 'right' }
        ]
      } else {
        return columns
      }
    }
  },
  watch: {
    mode () {
      this.loadData()
      this.loadUnitList()
    }
  },
  methods: {
    async importData ({ target }, type) {
      if (!target.files.length) {
        return
      }
      const keyMap = type === 1 ? [
        { title: '项目编码', key: 'pfmProjectHisCode' },
        { title: '数量', key: 'num' },
        { title: '开单科室', key: 'billingUnitName' },
        { title: '执行科室', key: 'executeUnitName' },
      ] : [
        { title: 'zyCode', key: 'zyCode', mapping: false },
        { title: 'patientName', key: 'patientName', mapping: false },
        { title: '收费项目', key: 'pfmProjectName' },
        { title: '发票项目(一级)', key: 'fpxmPfmProjectTypeName' },
        { title: '收费类别(二级)', key: 'sflbPfmProjectTypeName' },
        { title: '金额', key: 'price' },
        { title: '工作量', key: 'num' },
        { title: 'income', key: 'income', mapping: false },
        { title: '开单科室', key: 'billingOrgName' },
        { title: '执行科室', key: 'executeOrgName' },
        { title: '开单医生', key: 'kdysWorkerName' },
        { title: 'zxrWorkerName', key: 'zxrWorkerName', mapping: false },
        { title: 'zdysWorkerName', key: 'zdysWorkerName', mapping: false },
        { title: 'mzysWorkerName', key: 'mzysWorkerName', mapping: false },
        { title: 'xhhsWorkerName', key: 'xhhsWorkerName', mapping: false },
        { title: 'qxhsWorkerName', key: 'qxhsWorkerName', mapping: false }
      ]

      try {
        this.$Spin.show({
          render: (h) => {
            return h('div', [
              h('Icon', {
                'class': 'demo-spin-icon-load',
                props: {
                  type: 'ios-loading',
                  size: 18
                }
              }),
              h('div', '上传中...')
            ])
          }
        })
        const datas = await xlsxDataMapping(target.files[0], keyMap)

        if (type === 1) {
          datas.forEach((v, i) => {
            if (i > 0 && v.num === null) {
              v.num = datas[i - 1].num
            }
          })
        }

        await request('/performance/pfm_project_bill/batch', 'post', {
          pfmMonthId: this.pfmMonthId,
          type, datas
        })
        this.$Spin.hide();
        this.$Message.success('导入完成')
        if (type === 1) {
          this.sourcePanel.common = false
        } else {
          this.sourcePanel.his = false
        }
        this.loadData()
      } catch (e) {
        this.$Spin.hide();
        this.$Modal.error({ title: '导入失败', content: e.message })
      }
      target.value = null
    },
    async dataSync () {
      this.loading.sync = true
      try {
        await request('/performance/pfm_project_bill/sync', 'post', { pfmMonthId: this.pfmMonthId })
        this.loadData()
      } catch (e) {
        console.error(e)
      }
      this.loading.sync = false
    },
    async truncate (type) {
      this.loading.sync = true
      try {
        await request('/performance/pfm_project_bill/clear', 'post', { pfmMonthId: this.pfmMonthId, type })
        if (type === 1) {
          this.sourcePanel.common = false
        } else {
          this.sourcePanel.his = false
        }
        this.loadData()
      } catch (e) {
        console.error(e)
      }
      this.loading.sync = false
    },
    onSortChange ({ column, key, order }) {
      this.query.sort = { key: column.sortKey || key, type: order }
      this.loadData()
    },
    onPageChange (num) {
      this.page.num = num
      this.loadData()
    },
    onSearch (query) {
      if (query) {
        Object.assign(this.query, query)
      }
      this.page.num = 1
      this.loadData()
    },
    onSelectionChange (e) {
      this.selectionList = e
      this.msg.batchAssign = null
      this.batch.assginPfmMonthId = null
      this.batch.show = this.selectionList.length > 0
    },
    itemDetails (item) {
      this.formData = item
      this.status.details = true
    },
    itemEdit (item) {
      this.formData = JSON.parse(JSON.stringify(item))
      this.status.drawer = true
    },
    backClick () {
      this.$emit('back')
    },
    async initMonth () {
      const pfmMonthId = this.pfmMonthId ? this.pfmMonthId : this.initPfmMonthId
      if (!pfmMonthId) {
        return this.$Message.success('请选择要初始化的月份')
      }
      try {
        this.loading.init = true
        await request('/performance/pfm_project_bill/init_month', 'post', { pfmMonthId })
        this.loading.init = false
        this.$Message.success('初始化成功')
        this.page.num = 1
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '初始化失败', content: e.message })
        console.error(e)
        this.loading.init = false
      }
    },
    async batchDel () {
      try {
        this.loading.batch = true
        const promise = this.selectionList.map(v => {
          return request('/performance/pfm_project_bill/' + v.id, 'delete')
        })
        await Promise.all(promise)
        this.$Message.success('已删除')
        this.loading.batch = false
        this.batch.show = false
        this.page.num = 1
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '删除失败', content: e.message })
        this.loading.batch = false
      }
    },
    async batchAssignMonth () {
      const pfmMonthId = this.batch.assginPfmMonthId
      if (!pfmMonthId) {
        return this.msg.batchAssign = '请选择要分配的月份'
      }

      const hasMonthId = this.selectionList.filter(v => v.pfmMonthId).map(v => v.name)
      if (hasMonthId.length) {
        return this.msg.batchAssign = hasMonthId.join('、') + '已分配，不可重复分配'
      }

      const ids = this.selectionList.map(v => v.id).join(',')
      try {
        this.loading.batch = true
        await request('/performance/pfm_project_bill/to_month', 'post', { pfmMonthId, ids })
        this.loading.batch = false
        this.$Message.success('分配成功')
        this.loadData()
        this.batch.show = false
      } catch (e) {
        this.$Modal.error({ title: '分配失败', content: e.message })
        console.error(e)
        this.loading.batch = false
      }
    },
    async batchRemoveMonth () {
      try {
        this.loading.batch = true
        const promise = this.selectionList.map(v => {
          return request('/performance/pfm_project_bill/remove_month/' + v.id, 'post')
        })
        await Promise.all(promise)
        this.$Message.success('操作成功')
        this.loading.batch = false
        this.batch.show = false
        this.page.num = 1
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '操作失败', content: e.message })
        this.loading.batch = false
      }
    },
    async submit () {
      this.loading.submit = true
      try {
        if (!this.formData.pfmMonthId && this.pfmMonthId) {
          this.formData.pfmMonthId = this.pfmMonthId
        }

        if (this.formData.pfmProjectId) {
          const singlePfmConfig = await request('/performance/pfm_project/single', 'get', { pfmProjectId: this.formData.pfmProjectId })
          this.formData.singlePfmConfig = JSON.stringify(singlePfmConfig)
        }

        if (this.formData.pfmMonthId && !this.formData.id) {
          this.formData.initData = true
        }

        if (this.formData.basisTime instanceof Date) {
          this.formData.basisTime = this.$datetime.format(this.formData.basisTime, 'Y-M-D H:I:S')
        }

        if (this.formData.id) {
          await request('/performance/pfm_project_bill/' + this.formData.id, 'put', this.formData)
        } else {
          await request('/performance/pfm_project_bill', 'post', this.formData)
          this.page.num = 1
        }

        this.status.drawer = false
        this.loading.submit = false
        this.$Message.success('操作成功')
        this.loadData()
      } catch (e) {
        this.loading.submit = false
      }
    },
    async itemDel (item) {
      try {
        await request('/performance/pfm_project_bill/' + item.id, 'delete')
        this.$Message.success('删除成功')
        this.loadData()
      } catch (e) {
        this.$Message.success('删除失败')
      }
    },
    async loadData () {
      this.loading.load = true

      const params = {
        pfmMonthId: this.pfmMonthId,
        pageNum: this.page.num,
        pageSize: this.page.size
      }

      Object.keys(this.query).filter(key => !['key', 'value', 'sort', 'isSinglePfm', 'isOutUnit'].includes(key) && this.query[key] !== null).forEach(key => {
        params[key] = this.query[key]
      })

      if (this.query.key && this.query.value) {
        params[this.query.key] = this.query.value
      }

      if (this.query.isSinglePfm !== 'all') {
        params.isSinglePfm = this.query.isSinglePfm === 'yes' ? 1 : 0
      }
      if (this.query.isOutUnit !== 'all') {
        params.isOutUnit = this.query.isOutUnit === 'yes' ? 1 : 0
      }

      if (this.query.sort && this.query.sort.key && this.query.sort.type !== 'normal') {
        params.sortKey = this.query.sort.key
        params.sortType = this.query.sort.type
      }

      let res
      if (this.$authFunsProxyNoRoute['performance.calculate.index-manage'] || this.$authFunsProxyNoRoute['performance.calculate.index-editor']) {
        res = await request('/performance/pfm_project_bill', 'get', params)
      } else {
        res = await request('/performance/pfm_project_bill/by_auth', 'get', params)
      }
      
      this.page.total = res.total
      const dataList = res.data
      dataList.forEach(item => {
        try {
          item.singlePfmConfig = item.singlePfmConfig ? JSON.parse(item.singlePfmConfig) : []
        } catch (e) {
          item.singlePfmConfig = []
        }
        item.pfmMonthStatusText = getPfmMonthStatusText(item.pfmMonthStatus)
        const type = this.projectBillType.find(v => v.value === item.type)
        item.typeText = type ? type.label : '未知类型'
      })
      this.dataList = dataList
      this.loading.load = false
    },
    addClick () {
      this.formData = getDefault()
      this.status.drawer = true
    },
    resize () {
      this.tableHeight = this.$refs.content.offsetHeight
      this.page.size = Math.ceil((this.tableHeight - 40) / 48)
    },
    async loadUnitList () {
      if (this.$authFunsProxyNoRoute['performance.calculate.index-manage'] || this.$authFunsProxyNoRoute['performance.calculate.index-editor']) {
        this.unitList = await this.$store.dispatch('loadPfmUnitList')
      } else {
        this.unitList = await request('/performance/pfm_unit/by_auth')
      }
    }
  },
  async mounted () {
    window.addEventListener('resize', this.resize)
    this.resize()

    this.$store.dispatch('loadPfmOrgList')
    this.$store.dispatch('loadPfmMonthList')
    this.$store.dispatch('loadPfmProjectTypeList')
    
    this.loadUnitList()
    this.loadData()
  },
  destroyed () {
    this.dataList = []
    this.unitList = []
    window.removeEventListener('resize', this.resize)
  },
}
</script>
